import { getLocalStorageItem, setLocalStorageItem } from "@/app/api/localStorage";
import {
  CallbackCodes,
  EOperationOptions,
  Annotation,
  DocumentTypeMapEnToKo,
  DocumentTypeMapKoToEn,
  DocumentTypeMapKoToCasenoteEndpoint,
  CasenoteEndpoint,
  SejongLegalGroupMap,
  AbortCodes,
} from "@/globalEnum";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "@/lib/store";
import {
  updateSourceFbIdList,
  upsertMyChatsToPool,
  upsertFactblockListToPool,
  upsertSourceListToPool,
  toggleFbExistancePool,
  updateNewChat,
  updateNewChatWithNewTitle,
  deleteMyChatsFromPool,
} from "../pool/poolSlice";
import { set } from "lodash";
import { JudgmentSearchCondition } from "@/components/chatbox/query/findType/JudgmentSearchFilterEnums";
import _ from "lodash";
import {
  mapFactblockFromGeneratedReferences,
  selectAnswerTypeMessageContentTuple,
  selectChatMode,
  selectCurrentChatId,
  selectExternalAnswerTypeFilterState,
  selectExternalFindTypeFilterStateBeta,
  selectFactblockSummary,
  selectFactblockSummaryState,
  selectGroupAnswerTypeFilterState,
  selectGroupFindTypeFilterState,
  selectInternalAnswerTypeFilterState,
  selectInternalFindTypeFilterState,
  selectLLMModel,
  selectLatestAnswerMessageId,
  selectLatestAnswerMessageReusableResults,
  selectLatestAnswerMessageReusableResultsWithSuperlawyer,
  selectLatestMessageId,
  selectLatestQuestion,
  selectOldResponseMessageId,
  selectShowBookmark,
} from "./selectors";
import { internalDocumentMapper } from "@/app/api/mapper/find/internal/internalDocument";
import { internalFactblockMapper } from "@/app/api/mapper/find/internal/internalFactblock";
import {
  compareFactBlockSummaries,
  selectBookmarkedFactblocksToChat,
  selectFbTypeFromCache,
} from "../pool/selectors";
import {
  insertFactblocksToDB,
  insertSourcesToDB,
  fetchDocument,
  fetchFactblock,
  fetchMessages,
  fetchChat,
} from "./actions";
import { casenoteFactblockMapper } from "@/app/api/mapper/find/external/externalFactblock";
import { generatedFactblockMapper } from "@/app/api/mapper/find/external/generatedExternalFactblock";
import { userGeneratedFactblockMapper } from "@/app/api/mapper/find/internal/userGeneratedFactblock";
import {
  checkNonZeroValues,
  filterSearchParamEmptyKeys,
  getFormattedTimestamp,
  groupByReferenceAndGenerateSource,
  mapReferenceToFb,
  messageParingIdGenerator,
  reverseString,
} from "@/lib/utils/math";
import { CasenoteAPIMOutboundMapper } from "@/app/api/mapper/find/external/casenoteApim";
import {
  GACreateChatEvent,
  GACreateFeedbackEvent,
  GACreateMessageEvent,
  GAFBAnnotationEvent,
  GARegenerateEvent,
} from "@/lib/utils/event";
import { updateMenuRouter } from "../user/userSlice";
import Sentry from "@/sentry.client.config";
import { computeFileHash } from "@/lib/utils/hash";
import { removeController, setController } from "@/lib/utils/abortControllerManager";

type TPromiseRequestStatus = "idle" | "pending" | "fulfilled" | "rejected";
type TPromiseResponse = { statusCode: number; status: string; records: any[] };
const latestOperator = getLocalStorageItem("SideMenu");
export type ChatState = {
  findTypeFilter: {
    internal: InternalDocuments;
    external: DocumentFilterStatusType;
    externalSimple: ExternalDocumentsSimple;
    group: TGroupFilterState;
  };
  answerTypeFilter: {
    internal: InternalDocuments;
    external: ExternalDocumentsSimple;
    group: TGroupFilterState;
  };
  showDiffCard: boolean;
  hideSideMenu: boolean;
  showBookmark: boolean;
  createChat: boolean;
  LLMModel: AIModel;
  originalFactBlockSummary: FactBlockSummary;
  findTypeFactblockAPIState: {
    internal: TPromiseRequestStatus;
    external: TPromiseRequestStatus;
    apiCall: TPromiseRequestStatus;
  };
  reflecTypeFactblockAPIState: {
    text: TPromiseRequestStatus;
    file: TPromiseRequestStatus;
    apiCall: TPromiseRequestStatus;
  };
  superlawyerAPIState: TPromiseRequestStatus;
  factagoraAPIState: TPromiseRequestStatus;
  answerTypeFactblockAPIState: { apiCall: TPromiseRequestStatus; streamingAction: boolean };
  generateFactblockAPIState: TPromiseRequestStatus;
  updateCurrentChat: TPromiseRequestStatus;
  callbackData: any;
  activeMessage: string;
};

export const initialFindTypeInternalDocumentFilterState: InternalDocuments = {
  OpinionLetter: true,
  LitigationDocument: true,
  Proposal: true,
  // Contract: true,
  Judgment: true,
  User: true,
};

export const initialAnswerTypeInternalDocumentFilterState: InternalDocuments = {
  OpinionLetter: true,
  LitigationDocument: true,
  Proposal: true,
  Judgment: true,
  User: true,
};

export const initialFindTypeExternalDocumentFilterState: ExternalDocumentsSimple = {
  // Legislation: true,
  // Judgment: true,
  // Adjudication: true,
  // InterpretativeGuidelines: true,
  Superlawyer: true,
};

export const initialAnswerTypeExternalDocumentFilterState: ExternalDocumentsSimple = {
  // Legislation: true,
  // Judgment: true,
  // Adjudication: true,
  // InterpretativeGuidelines: true,
  Superlawyer: true,
};
export const initialGroupDocumentFilterState: TGroupFilterState = {
  // ExternalDB: true,
  OpinionLetter: true,
  Contract: true,
  targetGroups: Object.keys(SejongLegalGroupMap),
};

export const initialExternalDocumentFilterState: {
  [key: string]: boolean | string | DocumentFilterStatusType;
} = {
  Legislation: {
    Law: false,
    PresidentialDecree: false,
    PrimeMinisterOrMinisterialDecree: false,
    Others: false,
  },
  Judgment: {
    JudgmentSearchCondition: [{ condition: JudgmentSearchCondition, message: "" }],
    Court: {
      SupremeCourt: false,
      HighCourt: false,
      DistrictCourt: false,
      ConstitutionalCourt: false,
    },
    CaseType: {
      Civil: false,
      Criminal: false,
      Administrative: false,
      Patent: false,
      Family: false,
    },
    LitigationResult: {
      PlaintiffDefeat: false,
      PlaintiffPartialVictory: false,
      PlaintiffVictory: false,
      AppealDismissed: false,
      AppealRejected: false,
      Remand: false,
      Dismissal: false,
      Imprisonment: false,
      Detention: false,
      Fine: false,
      Acquittal: false,
      SuspendedSentence: false,
    },
    SearchPeriod: { start: "", end: "" },
  },
  Adjudication: {
    PatentTrial: false,
    FairTradeCommission: false,
    TaxTribunal: false,
    PersonalInformationProtectionCommission: false,
    CentralLaborRelationsCommission: false,
    LocalLaborRelationsCommission: false,
    SearchPeriod: { start: "", end: "" },
  },
  InterpretativeGuidelines: {
    FinancialAuthorities: {
      LawInterpretation: false,
      NoActionLetter: false,
      Sanctions: false,
    },
    MinistryOfGovernmentLegislation: {
      LawInterpretationCases: false,
    },
    SearchPeriod: { start: "", end: "" },
  },
  Others: false,
};

export const initialFactblockSummary: FactBlockSummary = {
  Approval: [],
  Negation: [],
  Bookmark: [],
};

const initialChatState: ChatContext & ChatState = {
  chatId: "",
  userId: "",
  title: "",
  isPinned: false,
  chatFactblockContribution: 0,
  topics: [] as string[],
  factblockSummary: initialFactblockSummary,
  latestOperator: latestOperator ?? EOperationOptions.AnswerType,
  context: [] as Message[],
  findTypeFilter: {
    internal: initialFindTypeInternalDocumentFilterState,
    external: initialExternalDocumentFilterState,
    externalSimple: initialFindTypeExternalDocumentFilterState,
    group: initialGroupDocumentFilterState,
  },
  answerTypeFilter: {
    internal: initialAnswerTypeInternalDocumentFilterState,
    external: initialAnswerTypeExternalDocumentFilterState,
    group: initialGroupDocumentFilterState,
  },
  superlawyerAPIState: "idle",
  factagoraAPIState: "idle",
  findTypeFactblockAPIState: { internal: "idle", external: "idle", apiCall: "idle" },
  reflecTypeFactblockAPIState: { text: "idle", file: "idle", apiCall: "idle" },
  answerTypeFactblockAPIState: { apiCall: "idle", streamingAction: false },
  generateFactblockAPIState: "idle",
  updateCurrentChat: "idle",
  originalFactBlockSummary: {
    Approval: [],
    Negation: [],
    Bookmark: [],
  },
  showDiffCard: false,
  hideSideMenu: false,
  showBookmark: false,
  createChat: true,
  updateTime: getFormattedTimestamp(),
  callbackData: null,
  activeMessage: "",
  LLMModel: {
    chat_mode: "lego",
  },
};

const initialMessageComponentState: Partial<Message> = {
  formerId: "",
  isQuestion: true,
  feedback: "",
  operation: latestOperator,
  content: "",
};

export const chatContextSlice = createSlice({
  name: "chatContext",
  initialState: initialChatState,
  extraReducers: (builder) => {
    builder
      .addCase(answerTypeThreadHandler_V2.pending, (state) => {
        state.answerTypeFactblockAPIState.apiCall = "pending";
        state.createChat = false;
      })
      .addCase(answerTypeThreadHandler_V2.rejected, (state) => {
        state.answerTypeFactblockAPIState.apiCall = "rejected";
        state.createChat = false;
      })
      .addCase(answerTypeThreadHandler_V2.fulfilled, (state, action) => {
        state.answerTypeFactblockAPIState.apiCall = "fulfilled";
        state.createChat = false;
        const { userId } = action.payload;
        GACreateMessageEvent({
          messageId: state.context[state.context.length - 1].messageId,
          userId,
          operation: EOperationOptions.AnswerType,
        });
      })
      .addCase(findTypeThreadHandler.fulfilled, (state, action) => {
        state.findTypeFactblockAPIState.apiCall = "fulfilled";
        state.createChat = false;

        const { userId } = action.payload;
        GACreateMessageEvent({
          messageId: state.context[state.context.length - 1].messageId,
          userId,
          operation: EOperationOptions.FindType,
        });
      })
      .addCase(askSuperLawyer.pending, (state) => {
        state.superlawyerAPIState = "pending";
        state.createChat = false;
      })
      .addCase(askSuperLawyer.fulfilled, (state, action) => {
        state.superlawyerAPIState = "fulfilled";
        state.createChat = false;
        const userId = action.payload.userId;
        GACreateMessageEvent({
          messageId: state.context[state.context.length - 1].messageId,
          userId,
          operation: "Superlawyer",
        });
      })
      .addCase(askSuperLawyer.rejected, (state) => {
        state.superlawyerAPIState = "rejected";
        state.createChat = false;
      })
      .addCase(cancelSuperLawyer.fulfilled, (state) => {
        state.superlawyerAPIState = AbortCodes.AbortSuperlawyer as any;
        state.createChat = false;
      })
      .addCase(askFactagora.pending, (state) => {
        state.factagoraAPIState = "pending";
        state.createChat = false;
      })
      .addCase(askFactagora.fulfilled, (state, action) => {
        const { userId, chatMode } = action.payload!;
        state.factagoraAPIState = "fulfilled";
        state.createChat = false;
        const operation = chatMode === "lego" ? "Factagora" : "GPT";
        GACreateMessageEvent({
          messageId: state.context[state.context.length - 1].messageId,
          userId,
          operation,
        });
      })
      .addCase(askFactagora.rejected, (state, action) => {
        const abortSignal = action.payload as any;
        state.factagoraAPIState = abortSignal ?? "rejected";
        state.createChat = false;
      })
      .addCase(reflectFileHandler.fulfilled, (state, action) => {
        state.reflecTypeFactblockAPIState.apiCall = "fulfilled";
        state.createChat = false;

        const { userId } = action.payload;
        GACreateMessageEvent({
          messageId: state.context[state.context.length - 1].messageId,
          userId,
          operation: EOperationOptions.ReflectType,
        });
      })
      .addCase(reflectFileHandler.rejected, (state, action) => {
        // const abortCode = action.payload as any;
        state.reflecTypeFactblockAPIState.apiCall = "rejected";
        state.createChat = false;
      })
      .addCase(reflectFileHandler.pending, (state) => {
        state.reflecTypeFactblockAPIState.apiCall = "pending";
        state.createChat = false;
      })
      .addCase(reflectTextHandler.fulfilled, (state, action) => {
        state.reflecTypeFactblockAPIState.apiCall = "fulfilled";
        state.createChat = false;

        const { userId } = action.payload;
        GACreateMessageEvent({
          messageId: state.context[state.context.length - 1].messageId,
          userId,
          operation: EOperationOptions.ReflectType,
        });
      })
      .addCase(reflectTextHandler.rejected, (state) => {
        state.reflecTypeFactblockAPIState.apiCall = "rejected";
        state.createChat = false;
      })
      .addCase(reflectTextHandler.pending, (state) => {
        state.reflecTypeFactblockAPIState.apiCall = "pending";
        state.createChat = false;
      })
      .addCase(regenerationHandler.fulfilled, (state, action) => {
        const { userId } = action.payload;
        for (const fbId of state.factblockSummary["Approval"]) {
          GARegenerateEvent({
            userId,
            approvedFbId: fbId,
          });
        }
      })
      .addCase(findTypeThreadHandler.rejected, (state) => {
        state.findTypeFactblockAPIState.apiCall = "rejected";
        state.createChat = false;
      })

      .addCase(findTypeThreadHandler.pending, (state) => {
        state.findTypeFactblockAPIState.apiCall = "pending";
        state.createChat = false;
      })
      .addCase(fetchInternalDoc.pending, (state) => {
        state.findTypeFactblockAPIState.internal = "pending";
      })
      .addCase(fetchInternalDoc.fulfilled, (state) => {
        state.findTypeFactblockAPIState.internal = "fulfilled";
      })
      .addCase(fetchInternalDoc.rejected, (state, action) => {
        const abortCode = action.payload as any;
        state.findTypeFactblockAPIState.internal = abortCode ?? "rejected";
      })
      .addCase(fetchCasenoteDocAPIM.pending, (state) => {
        state.findTypeFactblockAPIState.external = "pending";
      })
      .addCase(fetchCasenoteDocAPIM.fulfilled, (state) => {
        state.findTypeFactblockAPIState.external = "fulfilled";
      })
      .addCase(fetchCasenoteDocAPIM.rejected, (state) => {
        state.findTypeFactblockAPIState.external = "rejected";
      })
      .addCase(generateUserFactblockFromFile.pending, (state) => {
        state.reflecTypeFactblockAPIState.file = "pending";
      })
      .addCase(generateUserFactblockFromFile.fulfilled, (state) => {
        state.reflecTypeFactblockAPIState.file = "fulfilled";
      })
      .addCase(generateUserFactblockFromFile.rejected, (state, action) => {
        const abortCode = action.payload as any;

        state.reflecTypeFactblockAPIState.file = abortCode ?? "rejected";
      })
      .addCase(generateUserFactblockFromText.pending, (state) => {
        state.reflecTypeFactblockAPIState.text = "pending";
      })
      .addCase(generateUserFactblockFromText.fulfilled, (state) => {
        state.reflecTypeFactblockAPIState.text = "fulfilled";
      })
      .addCase(generateUserFactblockFromText.rejected, (state, action) => {
        const abortCode = action.payload as any;

        state.reflecTypeFactblockAPIState.text = abortCode ?? "rejected";
      })
      .addCase(generateCasenotePageFactBlock.pending, (state) => {
        state.generateFactblockAPIState = "pending";
      })
      .addCase(generateCasenotePageFactBlock.rejected, (state) => {
        state.generateFactblockAPIState = "rejected";
      })
      .addCase(generateCasenotePageFactBlock.fulfilled, (state) => {
        state.generateFactblockAPIState = "fulfilled";
      })
      .addCase(updateCurrentChat.pending, (state) => {
        state.updateCurrentChat = "pending";
      })
      .addCase(updateCurrentChat.rejected, (state) => {
        state.updateCurrentChat = "rejected";
      })
      .addCase(updateCurrentChat.fulfilled, (state) => {
        state.updateCurrentChat = "fulfilled";
      });
  },
  reducers: {
    initiateChatInstance(state, action: PayloadAction<{ chatId: string; title?: string }>) {
      state.chatId = action.payload.chatId;
      state.title = action.payload.title ?? "";
      state.isPinned = initialChatState.isPinned;
      state.latestOperator = latestOperator;
      state.chatFactblockContribution = initialChatState.chatFactblockContribution;
      state.topics = initialChatState.topics;
      state.factblockSummary = initialChatState.factblockSummary;
      state.originalFactBlockSummary = initialChatState.originalFactBlockSummary;
      state.context = initialChatState.context;
      state.findTypeFilter = _.cloneDeep(state.findTypeFilter);
      state.answerTypeFilter = _.cloneDeep(state.answerTypeFilter);
      state.findTypeFactblockAPIState = { internal: "idle", external: "idle", apiCall: "idle" };
      state.reflecTypeFactblockAPIState = { text: "idle", file: "idle", apiCall: "idle" };
      state.answerTypeFactblockAPIState = { apiCall: "idle", streamingAction: false };
      state.generateFactblockAPIState = "idle";
      state.updateCurrentChat = "idle";
      state.superlawyerAPIState = "idle";
      state.factagoraAPIState = "idle";
    },
    clearThreadAndAnnotation(state) {
      state.context = [];
      state.chatId = "";
      state.title = "";
      state.showDiffCard = false;
      state.factblockSummary = initialChatState.factblockSummary;
      state.findTypeFactblockAPIState = { internal: "idle", external: "idle", apiCall: "idle" };
      state.reflecTypeFactblockAPIState = { text: "idle", file: "idle", apiCall: "idle" };
      state.answerTypeFactblockAPIState = { apiCall: "idle", streamingAction: false };
      state.generateFactblockAPIState = "idle";
      state.updateCurrentChat = "idle";
      state.superlawyerAPIState = "idle";
      state.factagoraAPIState = "idle";
    },
    clearAPIState(state) {
      state.findTypeFactblockAPIState = { internal: "idle", external: "idle", apiCall: "idle" };
      state.reflecTypeFactblockAPIState = { text: "idle", file: "idle", apiCall: "idle" };
      state.answerTypeFactblockAPIState = { apiCall: "idle", streamingAction: false };
      state.generateFactblockAPIState = "idle";
      state.updateCurrentChat = "idle";
      state.superlawyerAPIState = "idle";
      state.factagoraAPIState = "idle";
    },
    updateShowDiffCard(state, action: PayloadAction<boolean>) {
      state.showDiffCard = action.payload;
    },
    updateActiveMessage(state, action: PayloadAction<string>) {
      state.activeMessage = action.payload;
    },
    toggleSideMenuOpenState(state) {
      const newValue = !state.hideSideMenu;
      state.hideSideMenu = newValue;
    },
    updateSideMenuHideState(state, action: PayloadAction<boolean>) {
      const newValue = action.payload;
      state.hideSideMenu = newValue;
    },
    updateBookmarkShowState(state, action: PayloadAction<boolean>) {
      const newValue = action.payload;
      state.showBookmark = newValue;
    },
    updateCreateChatState(state, action: PayloadAction<boolean>) {
      const newValue = action.payload;
      state.createChat = newValue;
    },
    throwSuperlawyerError(state) {
      state.superlawyerAPIState = "rejected";
      state.createChat = false;
    },
    throwFactagoraAnswerError(state) {
      state.factagoraAPIState = "rejected";
      state.createChat = false;
    },
    updateCurrentChatState(state, action: PayloadAction<ChatContext>) {
      state.chatId = action.payload.chatId;
      state.title = action.payload.title;
      state.isPinned = action.payload.isPinned;
      state.latestOperator = action.payload.latestOperator;
      state.chatFactblockContribution = action.payload.chatFactblockContribution;
      state.topics = action.payload.topics;
      state.factblockSummary = action.payload.factblockSummary;
      state.context = action.payload.context;
      state.updateTime = action.payload.updateTime;
    },
    appendMessage(
      state,
      action: PayloadAction<
        Partial<Message> & {
          messageId?: string;
          ask?: { superlawyer: boolean; factagora: boolean };
        }
      >
    ) {
      const messageComponent: Partial<Message> = {
        ...initialMessageComponentState,
        ...action.payload,
      };
      const newMessage: Message = {
        messageId: action.payload.messageId ?? uuidv4(),
        chatId: state.chatId,
        formerId: messageComponent.formerId ?? "",
        isQuestion: messageComponent.isQuestion ?? true,
        feedback: messageComponent.feedback ?? "",
        operation: messageComponent.operation ?? latestOperator,
        content: messageComponent.content ?? "",
        facblockSummaryState: messageComponent.facblockSummaryState!,
        updateTime: getFormattedTimestamp(),
        ask: messageComponent.ask,
        model: messageComponent.model ?? "",
        chatMode: messageComponent.chatMode ?? "",
      };
      state.context = [...state.context, newMessage];
      state.hideSideMenu = true;
    },
    recreateMessage(state, action: PayloadAction<Partial<Message> & { messageId?: string }>) {
      const messageComponent: Partial<Message> = {
        ...initialMessageComponentState,
        ...action.payload,
      };
      const newMessage: Message = {
        messageId: action.payload.messageId ?? uuidv4(),
        chatId: state.chatId,
        formerId: messageComponent.formerId ?? "",
        isQuestion: messageComponent.isQuestion ?? true,
        feedback: messageComponent.feedback ?? "",
        operation: messageComponent.operation ?? latestOperator,
        content: messageComponent.content ?? "",
        updateTime: getFormattedTimestamp(),
        facblockSummaryState: messageComponent.facblockSummaryState!,
        model: messageComponent.model ?? "",
        chatMode: messageComponent.chatMode ?? "",
      };
      state.context.push(newMessage);
    },
    updateMessageContent(state, action: PayloadAction<{ messageId: string; content: string }>) {
      const { messageId, content } = action.payload;
      const messageIndex = state.context.findIndex((message) => message.messageId === messageId);
      if (messageIndex !== -1) {
        state.context[messageIndex].content = content;
      }
    },
    updateMessageFactblockSummaryState(
      state,
      action: PayloadAction<{ messageId: string; facblockSummaryState: FactBlockSummary }>
    ) {
      const { messageId, facblockSummaryState } = action.payload;
      const messageIndex = state.context.findIndex((message) => message.messageId === messageId);
      if (messageIndex !== -1) {
        state.context[messageIndex].facblockSummaryState = facblockSummaryState;
      }
    },
    updateFoundDocument: (
      state,
      action: PayloadAction<{
        messageId: string;
        content: Partial<FindResponse>;
      }>
    ) => {
      const { messageId, content } = action.payload;
      const message: Message | undefined = state.context.find((msg) => msg.messageId === messageId);
      if (message) {
        message.content = {
          ...(message.content as FindResponse),
          ...(content as Source[]),
        };
      }
    },
    updateReflectedFactblock: (
      state,
      action: PayloadAction<{
        messageId: string;
        content: ReflectResponse;
      }>
    ) => {
      const { messageId, content } = action.payload;

      const messageIndex = state.context.findIndex((msg) => msg.messageId === messageId);
      if (messageIndex === -1) return;
      state.context[messageIndex] = {
        ...state.context[messageIndex],
        content: _.cloneDeep(content),
      };
    },
    updateLatestOperator(state, action: PayloadAction<EOperationOptions>) {
      state.latestOperator = action.payload;
    },
    updateFactBlockSummary(state, action: PayloadAction<FactBlockSummary>) {
      state.factblockSummary = action.payload;
    },
    updateChatTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    updateOriginalFactBlockSummary(state, action: PayloadAction<FactBlockSummary>) {
      state.originalFactBlockSummary = action.payload;
    },
    updateInternalFindFilter(state, action: PayloadAction<InternalDocuments>) {
      state.findTypeFilter.internal = action.payload;
    },
    updateExternalFindFilter(state, action: PayloadAction<DocumentFilterStatusType>) {
      state.findTypeFilter.external = action.payload;
    },
    updateExternalFindFilterBeta(state, action: PayloadAction<ExternalDocumentsSimple>) {
      state.findTypeFilter.externalSimple = action.payload;
    },
    updateGroupFindFilter(state, action: PayloadAction<TGroupFilterState>) {
      state.findTypeFilter.group = action.payload;
    },
    updateInternalAnswerFilter(state, action: PayloadAction<InternalDocuments>) {
      state.answerTypeFilter.internal = action.payload;
    },
    updateExternalAnswerFilter(state, action: PayloadAction<ExternalDocumentsSimple>) {
      state.answerTypeFilter.external = action.payload;
    },
    updateGroupAnswerFilter(state, action: PayloadAction<TGroupFilterState>) {
      state.answerTypeFilter.group = action.payload;
    },
    isAnswerStreaming(state, action: PayloadAction<boolean>) {
      state.answerTypeFactblockAPIState.streamingAction = action.payload;
    },
    updateDeepNestedField: (
      state,
      action: PayloadAction<{
        path: string[];
        value: boolean | string | TJudgmentSearchCondition[] | TSearchPeriod;
      }>
    ) => {
      const { path, value } = action.payload;
      state = {
        ...state,
        findTypeFilter: {
          ...state.findTypeFilter,
          external: set({ ...state.findTypeFilter.external }, path, value),
        },
      };
    },
    setCallbackData(state, action: PayloadAction<any>) {
      state.callbackData = action.payload;
    },
    updateLLMModel(state, action: PayloadAction<AIModel>) {
      state.LLMModel = action.payload;
    },
    disableAbortFactagoraFind(state) {
      state.findTypeFactblockAPIState.internal = AbortCodes.DisableAbortFactagoraFind as any;
    },
    disableAbortFactagorReflect(state) {
      state.reflecTypeFactblockAPIState.file = AbortCodes.DisableAbortFactagoraReflect as any;
      state.reflecTypeFactblockAPIState.text = AbortCodes.DisableAbortFactagoraReflect as any;
    },
  },
});

export const setPersistentType = createAsyncThunk(
  "chatContext/setPersistentType",
  async (type: EOperationOptions, { dispatch, getState }) => {
    if (!type) return;
    setLocalStorageItem("SideMenu", type);
    dispatch(updateLatestOperator(type));
  }
) as any;

export const updateMessageFBState = createAsyncThunk(
  "chatState/updateMessageFBState",
  async (_, { dispatch, getState }) => {
    const latestMessageId = selectLatestMessageId(getState());
    const chatId = selectCurrentChatId(getState());
    if (!latestMessageId) return;
    const factblockSummary = selectFactblockSummary(getState());
    dispatch(
      updateMessageFactblockSummaryState({
        messageId: latestMessageId,
        facblockSummaryState: factblockSummary,
      })
    );
    dispatch(
      updateChatFactblockSummary({
        chatId,
        factblockSummary,
      })
    );
  }
) as any;

export const regenerationHandler = createAsyncThunk(
  "answerTypeThreadHandler/regenerationHandler",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const latestQuestion = selectLatestQuestion(getState());
    if (!latestQuestion) return;
    const chatId = selectCurrentChatId(getState());
    const factblockSummary = selectFactblockSummary(getState());

    try {
      dispatch(updateMessageFBState());
      dispatch(
        updateChatFactblockSummary({
          chatId,
          factblockSummary,
        })
      );
      dispatch(setPersistentType(EOperationOptions.AnswerType));
      if (typeof latestQuestion.content === "string") {
        dispatch(answerTypeThreadHandler_V2(latestQuestion.content));
      } else {
        // 챗을 만든 후 단 한번의 자연어 질의가 없이 upsert 직후 바로 답변을 요청하는 경우
        dispatch(answerTypeThreadHandler_V2(Object.values(latestQuestion.content).join(",")));
      }

      dispatch(updateShowDiffCard(false));
      const userId = state.user.userId!;
      return { userId };
    } catch (e) {
      console.error(e);
    }
  }
) as any;

const answerTypeBodyFormatter = createAsyncThunk(
  "answerTypeThreadHandler/answerTypeBodyFormatter",
  async (params: { message: string }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const factblockSummary = selectFactblockSummary(state) ?? {};
    const bookmarkedFbList = selectBookmarkedFactblocksToChat(state) ?? [];
    const showBookmark = selectShowBookmark(state) ?? false;

    let finalBody: TAnswerTypeBody = {
      prev_qna_list: [],
      question: params.message,
      yellow_flagged_fbs: [],
      green_flagged_fbs: [],
      red_flagged_fbs: [],
      reusable_results: { legal_explanation: "", relevant_legislations: [], partial_answers: [] },
      search_params: { sejong: [], sejong_selected: [] },
    };

    /** red_flagged_fbs*/
    const _red_flagged_fbs = factblockSummary[Annotation.Negation] ?? [];
    if (_red_flagged_fbs.length) {
      for (const fbId of _red_flagged_fbs) {
        try {
          const cachedFbData = selectFbTypeFromCache(fbId)(state);
          if (cachedFbData) finalBody.red_flagged_fbs.push(cachedFbData);
        } catch (error) {
          console.warn(`Failed to fetch cached data for red_flagged_fbs with ID ${fbId}:`, error);
        }
      }
    }

    /** green_flagged_fbs*/
    const _green_flagged_fbs = factblockSummary[Annotation.Approval] ?? [];
    if (_green_flagged_fbs.length) {
      for (const fbId of _green_flagged_fbs) {
        try {
          const cachedFbData = selectFbTypeFromCache(fbId)(state);
          if (cachedFbData) finalBody.green_flagged_fbs.push(cachedFbData);
        } catch (error) {
          console.warn(`Failed to fetch cached data for green_flagged_fbs with ID ${fbId}:`, error);
        }
      }
    }

    /** yellow_flagged_fbs*/
    const _yellow_flagged_fbs = factblockSummary[Annotation.Bookmark] ?? [];
    if (_yellow_flagged_fbs.length) {
      for (const fbId of _yellow_flagged_fbs) {
        try {
          const cachedFbData = selectFbTypeFromCache(fbId)(state);
          if (cachedFbData) finalBody.yellow_flagged_fbs.push(cachedFbData);
        } catch (error) {
          console.warn(
            `Failed to fetch cached data for yellow_flagged_fbs with ID ${fbId}:`,
            error
          );
        }
      }
      if (showBookmark) {
        finalBody.yellow_flagged_fbs.push(...bookmarkedFbList);
      }
    }

    /** prev_qna_list*/
    const prev_qna_list = selectAnswerTypeMessageContentTuple(getState()) ?? [];
    finalBody.prev_qna_list = prev_qna_list;

    /** search_params*/
    const internalAnswerTypeFilter = selectInternalAnswerTypeFilterState(state) ?? {};
    const internalGroupAnswerTypeFilter = selectGroupAnswerTypeFilterState(state) ?? {};
    const externalAnswerTypeFilter = selectExternalAnswerTypeFilterState(state) ?? {};

    const internalFilters =
      filterSearchParamEmptyKeys({
        internal: internalAnswerTypeFilter,
        internalGroup: internalGroupAnswerTypeFilter,
        external: externalAnswerTypeFilter,
        findInternal: true,
      }) ?? {};

    // finalBody.search_params = { ...internalFilters };

    /** reusable_results*/
    const reusable_results = selectLatestAnswerMessageReusableResultsWithSuperlawyer(state) ?? {
      legal_explanation: "",
      relevant_legislations: [],
      partial_answers: [],
    };
    finalBody.reusable_results = reusable_results;

    return finalBody;
  }
) as any;

const answerTypeBodyFormatter_V2 = createAsyncThunk(
  "answerTypeThreadHandler/answerTypeBodyFormatter_V2",
  async (params: { message: string }, { dispatch, getState }) => {
    const state = getState() as RootState;

    const factblockSummary = selectFactblockSummary(state) ?? {};
    const bookmarkedFbList = selectBookmarkedFactblocksToChat(state) ?? [];
    const showBookmark = selectShowBookmark(state) ?? false;
    const prev_qna_list = selectAnswerTypeMessageContentTuple(state) ?? [];
    const reusable_results = selectLatestAnswerMessageReusableResultsWithSuperlawyer(state) ?? {
      legal_explanation: "",
      relevant_legislations: [],
      partial_answers: [],
    };
    const chat_mode = selectChatMode(state);
    const model = selectLLMModel(state);

    let finalBody: TAnswerTypeBodyV2 = {
      prev_qna_list: prev_qna_list ?? [],
      question: params.message,
      chat_mode,
      params: {
        model,
        search_params: {
          sejong: [],
          sejong_selected: [],
          user: [],
          // external_db: [],
          // unknown: [],
        },
        fb_flag_info: {
          yellow_flagged_fbs: [] as TFactblockLocation[],
          green_flagged_fbs: [] as TFactblockLocation[],
          red_flagged_fbs: [] as TFactblockLocation[],
        },
        reusable_results,
      },
    };

    const populateFlaggedFbs = (fbIds: string[], state: RootState): TFactblockLocation[] => {
      const flaggedFbs: TFactblockLocation[] = [];
      for (const fbId of fbIds) {
        try {
          const cachedFbData = selectFbTypeFromCache(fbId)(state);
          if (cachedFbData) flaggedFbs.push(cachedFbData as TFactblockLocation);
        } catch (error) {
          console.warn(`Failed to fetch cached data for fb with ID ${fbId}:`, error);
        }
      }
      return flaggedFbs;
    };

    finalBody.params.fb_flag_info!.red_flagged_fbs = populateFlaggedFbs(
      factblockSummary[Annotation.Negation] ?? [],
      state
    );
    finalBody.params.fb_flag_info!.green_flagged_fbs = populateFlaggedFbs(
      factblockSummary[Annotation.Approval] ?? [],
      state
    );
    const yellowFbs = populateFlaggedFbs(factblockSummary[Annotation.Bookmark] ?? [], state);
    if (showBookmark) yellowFbs.push(...bookmarkedFbList);
    finalBody.params.fb_flag_info!.yellow_flagged_fbs = yellowFbs;

    /** search_params*/
    const internalAnswerTypeFilter = selectInternalAnswerTypeFilterState(state) ?? {};
    const internalGroupAnswerTypeFilter = selectGroupAnswerTypeFilterState(state) ?? {};
    const externalAnswerTypeFilter = selectExternalAnswerTypeFilterState(state) ?? {};

    const internalFilters =
      filterSearchParamEmptyKeys({
        internal: internalAnswerTypeFilter,
        internalGroup: internalGroupAnswerTypeFilter,
        external: externalAnswerTypeFilter,
        findInternal: true,
      }) ?? {};

    finalBody.params.search_params = { ...internalFilters };

    // console.log("finalBodyV2", finalBody);
    return finalBody;
  }
);

export const fetchCasenoteDocAPIM = createAsyncThunk(
  "chatContext/fetchCasenoteDocAPIM",
  async (params: { casenote: TLegalFilter[]; query: string }, { dispatch, getState }) => {
    const { casenote, query } = params;
    let searchResult: Source[] = [];
    for (const filter of casenote) {
      const res = await fetch("/api/v2/caseNoteV2", {
        method: "POST",
        body: JSON.stringify({
          requestType: "fetch_case_note_data",
          payload: {
            searchType: DocumentTypeMapKoToCasenoteEndpoint[filter.legal_doc_type],
            data: {
              query,
            },
          },
        }),
      });

      if (res.ok) {
        const data: TPromiseResponse = await res.json();
        const casenoteAsSource = CasenoteAPIMOutboundMapper({
          casenoteApiRes: data.records as CasenoteAPIMOutbound[],
          sourceCategory: DocumentTypeMapKoToEn[filter.legal_doc_type],
        });
        searchResult = [...searchResult, ...casenoteAsSource];
        dispatch(upsertSourceListToPool(searchResult));
        insertSourcesToDB(searchResult);
      }
    }
    return searchResult;
  }
);

export const fetchCasenoteHTML = createAsyncThunk(
  "chatContext/fetchCasenoteHTML",
  async (
    params: { documentType: CasenoteSupportedType; docURL: string },
    { dispatch, getState }
  ) => {
    const { documentType, docURL } = params;
    const res = await fetch("/api/v2/caseNoteV2", {
      method: "POST",
      body: JSON.stringify({
        requestType: "fetch_case_note_html",
        payload: {
          searchType: `${CasenoteEndpoint.본문추출}`,
          documentType,
          data: {
            docURL,
          },
        },
      }),
    });
    const data = await res.json();
    return data.records[0].html;
  }
) as any;

export const answerTypeThreadHandler_V2 = createAsyncThunk(
  "chatContext/answerTypeThreadHandler_V2",
  async (message: string, { dispatch, getState }) => {
    const UUID = messageParingIdGenerator();
    const state = getState() as RootState;
    const latestQuestion = selectLatestQuestion(state);
    const factblockSummary = selectFactblockSummary(state);
    const externalAnswerType = selectExternalAnswerTypeFilterState(state);
    const internalAnswerType = selectInternalAnswerTypeFilterState(state);
    const areSomeChecked = (document: Record<string, boolean>) =>
      Object.values(document).some((checked) => checked);
    const chatMode = selectChatMode(state);
    const shouldAskOnlyGpt = chatMode === "vanilla";
    const shouldAskSuperlawyer = areSomeChecked(externalAnswerType) && !shouldAskOnlyGpt;
    const shouldAskFactagora = areSomeChecked(internalAnswerType);
    const userId = state.user.userId!;
    const chatId = state.chatContext.chatId;
    const gpt = selectLLMModel(state);

    try {
      // Initialize response content to be updated progressively
      let finalResponse = {
        // ...(shouldAskFactagora && { answer: null }),
        // ...(shouldAskSuperlawyer && {
        //   superLawyerResult: null,
        // }),
      } as any;
      // Step 1: Dispatch initial messages
      const requestData = {
        content: message,
        isQuestion: true,
        formerId: latestQuestion?.messageId ?? "",
        messageId: UUID.questionId,
        chatId,
        operation: EOperationOptions.AnswerType,
        facblockSummaryState: factblockSummary,
      };
      const targetMessageId: string = UUID.responseId;
      const responseData = {
        formerId: UUID.questionId,
        chatId,
        messageId: targetMessageId,
        isQuestion: false,
        operation: EOperationOptions.AnswerType,
        facblockSummaryState: factblockSummary,
        model: gpt,
        chatMode,
        ask: { superlawyer: shouldAskSuperlawyer, factagora: shouldAskFactagora },
      };
      await dispatch(appendMessage(requestData));
      await dispatch(appendMessage(responseData));

      // Step 2: Initial save to Strapi for requestData
      try {
        await fetch("/api/v2/strapi", {
          method: "POST",
          body: JSON.stringify({
            requestType: "create_strapi_data",
            payload: { table: "messages", data: requestData },
          }),
        });
      } catch (strapiError) {
        Sentry.captureException(strapiError);
        console.error("Strapi error on initial save:", strapiError);
      }

      // 각 요청이 도착하면 상태만 업데이트 한 후 모두 도착했을때만 DB와 인터랙션 - 일찍 호출된 과거 상태값이 늦게 저장되면서 null로 덮어씌움 방지
      if (shouldAskFactagora || shouldAskSuperlawyer) {
        try {
          const promises = [];
          if (shouldAskFactagora) {
            const factagoraPromise = dispatch(askFactagora({ message }))
              .unwrap()
              .then((result: any) => {
                finalResponse = {
                  ...finalResponse,
                  ...result.content,
                  answer: result.content.answer,
                };
                console.log("factagora", finalResponse);
                dispatch(
                  updateMessageContent({
                    messageId: targetMessageId,
                    content: finalResponse as any,
                  })
                );
              })
              .catch((error) => {
                Sentry.captureException(error);
                console.error("Factblock API error:", error);
              });

            promises.push(factagoraPromise);
          }

          if (shouldAskSuperlawyer) {
            const superlawyerPromise = dispatch(
              // message_Id가 uuid여야 하기 때문에 질문 메세지의 uuid를 넘겨줌. 답변 메세지는 질문 메세지의 uuid를 반전시킨 값을 사용하고 있음
              askSuperLawyer({ messageId: UUID.questionId, query: message, chatId })
            )
              .unwrap()
              .then((result: any) => {
                if (result?.content) {
                  finalResponse = { ...finalResponse, superLawyerResult: result.content };
                  // console.log("superlawyer", finalResponse);
                  dispatch(
                    updateMessageContent({
                      messageId: targetMessageId,
                      content: finalResponse as any,
                    })
                  );
                }
              })
              .catch((error: any) => {
                Sentry.captureException(error);
                console.error("Error in askSuperLawyer:", error);
              });

            promises.push(superlawyerPromise);
          }

          await Promise.allSettled(promises);

          console.log("finalResponse", finalResponse);
          await dispatch(
            upsertMessageInStrapi({
              messageId: targetMessageId,
              data: { ...responseData, content: finalResponse },
            })
          );
        } catch (error) {
          Sentry.captureException(error);
          console.error("Error during parallel API calls:", error);
        }
      }

      return { userId };
    } catch (error) {
      Sentry.captureException(error);
      console.error("Unexpected error in answerTypeThreadHandler:", error);
    }
  }
) as any;

export const askFactagora = createAsyncThunk(
  "answerTypeThreadHandler/askFactagora",
  async (params: { message: string }, { dispatch, getState, rejectWithValue }) => {
    const { message } = params;
    const answerTypeBodyV2 = await dispatch(answerTypeBodyFormatter_V2({ message })).unwrap();
    const state = getState() as RootState;
    const userId = state.user.userId!;
    const chatId = state.chatContext.chatId;
    const chatMode = selectChatMode(state);
    const controller = new AbortController();
    setController(chatId, controller);

    try {
      const factblockResponse = await fetch("/api/v1/factBlock/", {
        method: "POST",
        body: JSON.stringify({
          // v2
          requestType: "qna_nouveau_v2",
          payload: {
            answerTypeBodyV2,
          },
        }),
        signal: controller.signal,
      });
      if (!factblockResponse.ok) {
        // 500 오류를 포함한 모든 HTTP 에러 처리, catch블럭으로 넘어감
        throw new Error(`askFactagora Factagora API error:: ${factblockResponse.status}`);
      }

      return { content: await factblockResponse.json(), userId, chatMode }!;
    } catch (error) {
      console.error("askFactagora Factagora API error:", error);

      if (controller.signal.aborted) {
        return rejectWithValue(AbortCodes.AbortFactagoraAnswer);
      } else {
        dispatch(throwFactagoraAnswerError());
      }
    } finally {
      // Clean up the AbortController
      removeController(chatId);
    }
  }
);

export const upsertMessageInStrapi = createAsyncThunk(
  "answerTypeThreadHandler/upsertMessageInStrapi",
  async (params: { messageId: string; data: PossibleResponseTypes }, { dispatch, getState }) => {
    let updateTarget: any;
    const chatId = selectCurrentChatId(getState());
    const state = getState() as RootState;
    const userId = state.user.userId!;

    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=messages&params=filters[messageId][$eq]=${params.messageId}`,
      {
        method: "GET",
      }
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;

    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error fetching from DB");
    }
    if (records.length > 0) {
      updateTarget = records[0];
    }

    if (!!updateTarget) {
      fetch("/api/v2/strapi", {
        method: "PUT",
        body: JSON.stringify({
          requestType: "update_strapi_data",
          payload: {
            table: "messages",
            data: {
              ...updateTarget,
              content: {
                ...updateTarget.content,
                //@ts-ignore
                superLawyerResult: (params.data.content as any).superLawyerResult,
              } as AnswerResponse,
            },
            id: updateTarget.id,
          },
        }),
      });
      // Update Redux state with merged content
      dispatch(
        updateMessageContent({
          messageId: updateTarget.messageId,
          //@ts-ignore
          content: {
            ...updateTarget.content,
            //@ts-ignore
            superLawyerResult: (params.data.content as any).superLawyerResult,
          } as AnswerResponse,
        })
      );
    } else {
      //@ts-ignore
      const { ask, ...rest } = params.data;
      try {
        fetch("/api/v2/strapi", {
          method: "POST",
          body: JSON.stringify({
            requestType: "create_strapi_data",
            payload: { table: "messages", data: rest },
          }),
        });

        const factblock = mapFactblockFromGeneratedReferences(getState());
        await insertFactblocksToDB({
          fbs: factblock,
          chatId,
          userId,
        });
        dispatch(upsertFactblockListToPool(factblock));
      } catch {
        console.error("create_strapi_data", rest);
      }
    }
  }
) as any;

export const updateMessageContentInStrapi = createAsyncThunk(
  "chatContext/updateMessageContentInStrapi",
  async (params: { messageId: string; content: PossibleResponseTypes }, { dispatch, getState }) => {
    let updateTarget: any;

    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=messages&params=filters[messageId][$eq]=${params.messageId}`,
      {
        method: "GET",
      }
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;

    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error fetching from DB");
    }
    if (records.length > 0) {
      updateTarget = records[0];
    }

    if (!!updateTarget) {
      await fetch("/api/v2/strapi", {
        method: "PUT",
        body: JSON.stringify({
          requestType: "update_strapi_data",
          payload: {
            table: "messages",
            data: {
              ...updateTarget,
              content: params.content,
            },
            id: updateTarget.id,
          },
        }),
      });
      // Update Redux state with merged content
      dispatch(
        updateMessageContent({
          messageId: updateTarget.messageId,
          content: params.content as any,
        })
      );
    } else {
      throw new Error(`No message found with id: ${params.messageId}`);
    }
  }
) as any;

export const askSuperLawyer = createAsyncThunk(
  "answerTypeThreadHandler/askSuperLawyer",
  async (params: { messageId: string; chatId: string; query: string }, { dispatch, getState }) => {
    const chatroomId = params.chatId;
    const messageId = params.messageId;
    const inputQuery = params.query;
    const userIdInUUID = uuidv4();
    const maxRetries = 50; // Maximum number of retries per fetch attempt
    const retryDelay = 2000; // Delay between retries (in milliseconds)
    const maxOverallRetries = 100; // Maximum number of overall retry attempts (for periodic calls)

    try {
      const initialRequest = await fetch("/api/v1/superlawyer", {
        method: "POST",
        body: JSON.stringify({
          api_id: process.env.NEXT_PUBLIC_SUPERLAWYER_API_ID,
          chatroom_id: chatroomId,
          message_id: messageId,
          user_id: userIdInUUID,
          input_data: {
            msg_dict: {
              query: inputQuery,
              all_legal_materials: true,
              legal_materials: [],
            },
          },
        }),
      });

      if (!initialRequest.ok) {
        // Handle non-200 responses
        throw new Error(`Failed to call Superlawyer API: ${initialRequest.statusText}`);
      }

      const initialData = await initialRequest.json();
      // Retrieve the data from Redis with periodic fetches
      const fetchUrl = `/api/dummy/superlawyer/callback/chat/${userIdInUUID}/${chatroomId}/${messageId}`;

      let overallAttempt = 0; // Track overall retries across fetches
      let accumulatedResponses = []; // Array to accumulate all periodic responses

      while (overallAttempt < maxOverallRetries) {
        let attempt = 0;
        let cachedResponse;
        const state = getState() as RootState;
        const superlawyerAPIState = state.chatContext.superlawyerAPIState;

        // Retry logic for fetching the cached data
        while (attempt < maxRetries) {
          try {
            // Try fetching the cached data
            cachedResponse = await fetch(fetchUrl, {
              headers: {
                "Content-Type": "application/json",
              },
            });
            const responseData = await cachedResponse.json();

            // Check if the response is okay (status 200)
            if (cachedResponse.ok) {
              // Add the new response to the accumulated list of responses
              accumulatedResponses.push(responseData);

              // Dispatch each response to update the store in real-time
              dispatch(setCallbackData(responseData));
              const state = getState() as RootState;

              // Check if the response contains the final msg_type (msg_type === 3)
              if (responseData.data && responseData.data.msg_type === 3) {
                console.log("Final response received. Exiting the loop.");
                const userId = state.user.userId!;
                return { content: responseData.data.content, userId }; // Return content and userId
              }

              // Break the retry loop to make the next overall request
              break;
            } else if (cachedResponse.status === 404) {
              console.log(`Attempt ${attempt + 1}: Data not found, retrying...`);
            } else if (responseData.data.content.code === 500) {
              dispatch(throwSuperlawyerError());
              return;
            } else {
              throw new Error(`Unexpected response: ${cachedResponse.statusText}`);
            }
          } catch (error) {
            console.log(`Attempt ${attempt + 1}: Error fetching data, retrying...`, error);
          }

          // Wait before trying again
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
          attempt++;
          // @ts-ignore
          if (superlawyerAPIState === AbortCodes.AbortSuperlawyer) {
            break;
          }
        }

        // If we exhausted all retries for this specific request and still have no valid response
        if (!cachedResponse || !cachedResponse.ok) {
          console.log(
            "Failed to fetch data after multiple attempts for this cycle. Retrying again overall..."
          );
        }

        // Wait before fetching again for the next periodic response
        await new Promise((resolve) => setTimeout(resolve, retryDelay));

        overallAttempt++;
        // @ts-ignore
        if (superlawyerAPIState === AbortCodes.AbortSuperlawyer) {
          break;
        }
      }

      // If we exhausted all overall retries and still have not completed the process
      if (overallAttempt >= maxOverallRetries) {
        overallAttempt = 0;
        dispatch(throwSuperlawyerError());
        throw new Error("Exceeded maximum retries for periodic responses.");
      }
    } catch (error) {
      console.error("Error in askSuperLawyer thunk:", error);
      dispatch(throwSuperlawyerError());
    }
  }
) as any;

export const askSuperLawyerEvaluation = createAsyncThunk(
  "answerTypeThreadHandler/askSuperLawyerEvaluation",
  async (params: { messageId: string }, { dispatch, getState }) => {
    const message_id = reverseString(params.messageId);
    const maxRetries = 50; // Maximum number of retries per fetch attempt
    const retryDelay = 2000; // Delay between retries (in milliseconds)
    const maxOverallRetries = 100; // Maximum number of overall retry attempts (for periodic calls)
    let overallAttempt = 0; // Track overall retries across fetches
    let accumulatedResponses = []; // Array to accumulate all periodic responses
    const fetchUrl = `/api/dummy/superlawyer/callback/evaluate/${message_id}`;

    const initialRequest = await fetch("/api/v1/superlawyer/evaluate", {
      method: "POST",
      body: JSON.stringify({
        message_id,
      }),
    });
    // console.log("askSuperLawyerEvaluation", initialRequest);

    while (overallAttempt < maxOverallRetries) {
      let attempt = 0;
      let cachedResponse;

      // Retry logic for fetching the cached data
      while (attempt < maxRetries) {
        try {
          // Try fetching the cached data
          cachedResponse = await fetch(fetchUrl, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          const responseData = await cachedResponse.json();

          // Check if the response is okay (status 200)
          if (cachedResponse.ok) {
            // Add the new response to the accumulated list of responses
            accumulatedResponses.push(responseData);

            // Dispatch each response to update the store in real-time
            dispatch(setCallbackData(responseData));

            // Check if the response contains the final msg_type (msg_type === 3)
            if (responseData.data && responseData.data.msg_type === 3) {
              console.log("Final response received. Exiting the loop.", responseData);
              const state = getState() as RootState;
              const userId = state.user.userId!;
              if (responseData.status_code === 200) {
                const evaluation = responseData.data?.content?.evaluation as Evaluation;
                const originalMessage = await dispatch(
                  fetchMessageFromDB(params.messageId)
                ).unwrap();
                const finalMessageWithEvaluation = {
                  ...originalMessage,
                  superLawyerResult: { ...originalMessage.superLawyerResult, evaluation },
                };
                await dispatch(
                  updateMessageContentInStrapi({
                    messageId: params.messageId,
                    content: finalMessageWithEvaluation,
                  })
                );
              }
              return { content: responseData.data.content, userId };
            }

            // Break the retry loop to make the next overall request
            break;
          } else if (cachedResponse.status === 404) {
            console.log(`Attempt ${attempt + 1}: Data not found, retrying...`);
          } else if (responseData.data.content.code === 500) {
            dispatch(throwSuperlawyerError());
            return;
          } else {
            throw new Error(`Unexpected response: ${cachedResponse.statusText}`);
          }
        } catch (error) {
          console.log(`Attempt ${attempt + 1}: Error fetching data, retrying...`, error);
        }

        // Wait before trying again
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        attempt++;
      }

      // If we exhausted all retries for this specific request and still have no valid response
      if (!cachedResponse || !cachedResponse.ok) {
        console.log(
          "Failed to fetch data after multiple attempts for this cycle. Retrying again overall..."
        );
      }

      // Wait before fetching again for the next periodic response
      await new Promise((resolve) => setTimeout(resolve, retryDelay));

      overallAttempt++;
    }
  }
) as any;

export const cancelSuperLawyer = createAsyncThunk(
  "answerTypeThreadHandler/cancelSuperLawyer",
  async (params: { messageId: string }, { dispatch, getState }) => {
    const message_id = reverseString(params.messageId);
    //app/api/v1/superlawyer/cancel
    const stop = await fetch("/api/v1/superlawyer/cancel", {
      method: "POST",
      body: JSON.stringify({
        message_id,
      }),
    });

    console.log("/api/v1/superlawyer/cancel/route.ts", await stop.json());
  }
) as any;

export const fetchMessageFromDB = createAsyncThunk(
  "answerTypeThreadHandler/fetchMessageFromDB",
  async (messageId: string, { dispatch, getState }) => {
    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=messages&params=filters[messageId][$eq]=${messageId}`,
      {
        method: "GET",
      }
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;

    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error fetching from DB");
    }
    if (records.length > 0) {
      dispatch(
        updateMessageContent({
          messageId,
          content: records[0].content,
        })
      );
      return records[0].content;
    }
  }
) as any;

/**
 * parent: answerTypeThreadHandler
 * desc: type answer field
 */
const typingActionHandler = createAsyncThunk(
  "answerTypeThreadHandler/typingActionHandler",
  async (
    params: {
      answer: string;
      targetMessageId: string;
    },
    { dispatch, getState }
  ) => {
    let { answer, targetMessageId } = params;
    const STARTSignal = '{"answer":"';
    const ENDSignal = "\\ENDOFANSWER";
    let finalAnswer = "";
    const chatId = selectCurrentChatId(getState());
    const state = getState() as RootState;
    const userId = state.user.userId!;

    async function processAnswerToTypeString() {
      let startIndex = answer.indexOf(STARTSignal);
      if (startIndex !== -1) {
        answer = answer.slice(startIndex + STARTSignal.length);
      }

      let endIndex = answer.indexOf(ENDSignal);
      if (endIndex !== -1) {
        finalAnswer = answer.slice(0, endIndex);
      } else {
        finalAnswer = answer;
      }
    }
    const typeEffect = (text: string) => {
      let i = 0;
      const typingSpeed = 0;

      dispatch(isAnswerStreaming(false));

      async function typeCharacter() {
        try {
          // Ensure the typing indicator is shown
          dispatch(isAnswerStreaming(true));
          // Type each character one by one
          while (i < text.length) {
            dispatch(
              updateMessageContent({
                messageId: targetMessageId,
                content: text.slice(0, i + 1),
              })
            );
            i++;
            await new Promise((resolve) => setTimeout(resolve, typingSpeed)); // Use await with Promise for delay
          }

          // Fetch message from the database after typing is complete
          let res;
          do {
            res = await dispatch(fetchMessageFromDB(targetMessageId));
          } while (!res.payload);

          if (res.meta.requestStatus === "fulfilled") {
            dispatch(isAnswerStreaming(false));
            dispatch(updateShowDiffCard(true));
            const factblock = mapFactblockFromGeneratedReferences(getState());

            // Insert factblocks to the database and update the state
            await insertFactblocksToDB({
              fbs: factblock,
              chatId,
              userId,
            });

            dispatch(upsertFactblockListToPool(factblock));
          } else {
            throw new Error("Error fetching from DB");
          }
        } catch (error) {
          console.error("An error occurred:", error);
          // Handle error appropriately, possibly updating state to show an error message
        }
      }

      typeCharacter();
    };

    await processAnswerToTypeString();
    typeEffect(finalAnswer);
  }
);

export const fetchInternalDoc = createAsyncThunk(
  "documents/fetchInternalDoc",
  async (
    params: { message: string; signal: AbortSignal },
    { dispatch, getState, rejectWithValue }
  ) => {
    const { message, signal } = params;
    const state = getState() as RootState;
    const internalFindTypeFilter = selectInternalFindTypeFilterState(state);
    const internalGroupFindTypeFilter = selectGroupFindTypeFilterState(state);
    const externalFilterForExceptions = selectExternalFindTypeFilterStateBeta(state);

    const filter = filterSearchParamEmptyKeys({
      findInternal: true,
      internal: internalFindTypeFilter,
      internalGroup: internalGroupFindTypeFilter,
      external: externalFilterForExceptions,
    });
    try {
      const firstPartyPromise = (await fetchDocument({
        requestType: "retrieve_internal_docfbs",
        message,
        filter: filter as any,
        documentMapper: internalDocumentMapper,
        signal,
      })) as Source[];

      if (!firstPartyPromise) return;
      dispatch(disableAbortFactagoraFind());
      await insertSourcesToDB(firstPartyPromise);
      dispatch(upsertSourceListToPool(firstPartyPromise));

      const getFindResponse = selectLatestAnswerMessageId(getState());
      if (!getFindResponse) return;

      dispatch(
        updateFoundDocument({
          messageId: getFindResponse,
          content: { firstPartyHits: firstPartyPromise },
        })
      );

      return firstPartyPromise;
    } catch (error) {
      if (error.message === AbortCodes.AbortFactagoraFind) {
        return rejectWithValue(AbortCodes.AbortFactagoraFind);
      }
    }
  }
);

export const generateCasenotePageFactBlock = createAsyncThunk(
  "page/generateCasenotePageFactblock",
  async (
    param: { sourceId: string; doc_url: string; eng_doc_type: ExternalDocumentType },
    { dispatch, getState }
  ) => {
    const message = selectLatestQuestion(getState())?.content as string;
    const state = getState() as RootState;
    const userId = state.user.userId!;
    const { sourceId, doc_url, eng_doc_type } = param;
    const legal_doc_type = DocumentTypeMapEnToKo[eng_doc_type];
    const chatId = selectCurrentChatId(getState());
    const generatedFB = (await fetchFactblock({
      requestType: "generate_casenote_pagefbs",
      message,
      factblockMapper: generatedFactblockMapper(selectLatestAnswerMessageId(getState())!),
      doc_url,
      legal_doc_type,
    })) as FactBlock[];
    await insertFactblocksToDB({ fbs: generatedFB, chatId, userId, table: "pages" });
    dispatch(upsertFactblockListToPool(generatedFB));
    const fbIdList = generatedFB.map((fb) => fb.fbId);
    dispatch(
      updateSourceFbIdList({
        sourceId,
        fbIdList,
      })
    );
    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=sources&params=filters[sourceId][$eq]=${sourceId}`,
      {
        method: "GET",
      }
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;

    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error duplicate check");
    }

    if (records.length > 0) {
      await fetch("/api/v2/strapi", {
        method: "PUT",
        body: JSON.stringify({
          requestType: "update_strapi_data",
          payload: { table: "sources", data: { fbIdList }, id: records[0].id },
        }),
      });
    }

    return generatedFB;
  }
) as any;

export const fetchInternalChunkFb = createAsyncThunk(
  "documents/fetchInternalChunkFb",
  async (
    params: { doc_ids: string[]; index: string; namespace: string },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const userId = state.user.userId!;
    const message = selectLatestQuestion(getState())?.content as string;
    const { doc_ids, index, namespace } = params;
    const messageId = selectLatestAnswerMessageId(getState());
    const chatId = selectCurrentChatId(getState());
    let internalFactblock: FactBlock[] = (await fetchFactblock({
      requestType: "retrieve_internal_chunkfbs",
      message,
      factblockMapper: internalFactblockMapper({
        messageId: messageId!,
        index,
        namespace: namespaceSlicer(namespace),
      }),
      doc_ids,
      index,
      namespace: namespaceSlicer(namespace),
    })) as FactBlock[];

    await insertFactblocksToDB({ fbs: internalFactblock, chatId, userId });
    dispatch(upsertFactblockListToPool(internalFactblock));
    const fbIdList = internalFactblock.map((fb) => fb.fbId);
    dispatch(
      updateSourceFbIdList({
        sourceId: doc_ids[0],
        fbIdList,
      })
    );
    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=sources&params=filters[sourceId][$eq]=${doc_ids[0]}`,
      {
        method: "GET",
      }
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;

    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error duplicate check");
    }

    if (records.length > 0) {
      await fetch("/api/v2/strapi", {
        method: "PUT",
        body: JSON.stringify({
          requestType: "update_strapi_data",
          payload: { table: "sources", data: { fbIdList }, id: records[0].id },
        }),
      });
    }

    return internalFactblock;
  }
) as any;

export const namespaceSlicer = (originalNamespace: string) => {
  return originalNamespace.includes("#") ? originalNamespace.split("#")[1] : originalNamespace;
};

export const fetchExternalFb = createAsyncThunk(
  "documents/fetchExternalFb",
  async (doc_urls: string[], { dispatch, getState }) => {
    let message = selectLatestQuestion(getState())?.content as string;
    const messageId = selectLatestAnswerMessageId(getState());
    const chatId = selectCurrentChatId(getState());
    const state = getState() as RootState;
    const userId = state.user.userId!;

    let casenoteFactblock = await fetchFactblock({
      requestType: "retrieve_casenote_pagefbs",
      // test 용 - 지우지마세요 existing factblock
      // (message = "게임에서 소비자 유인과 거래 여부"),
      // (doc_urls = ["/공정거래위원회/의결2018-204", "/공정거래위원회/약식2018-002"]),
      message,
      factblockMapper: casenoteFactblockMapper(messageId!),
      doc_urls,
    });

    if (!Array.isArray(casenoteFactblock) && casenoteFactblock === CallbackCodes.GenerateFB) {
      dispatch(toggleFbExistancePool(doc_urls[0]));
      return doc_urls[0];
    }
    let externalFactblock = [...(casenoteFactblock as FactBlock[])];
    externalFactblock.map(async (record, index) => {
      const annotation = await fetch(
        `/api/v2/strapi?requestType=fetch_strapi_data&table=annotations&params=filters[fbId][$eq]=${record.fbId}`
      );
      const responseData: TPromiseResponse = await annotation.json();
      const { records: annotationRecords } = responseData;
      if (annotationRecords.length > 0) {
        let match = _.find(externalFactblock, (i) =>
          annotationRecords.find((f) => f.fbId === i.fbId)
        );
        _.assign(match, { annotation: annotationRecords[0].annotation });
      }
    });
    insertFactblocksToDB({ fbs: externalFactblock, chatId, userId, table: "pages" });
    dispatch(upsertFactblockListToPool(externalFactblock));
    const fbIdList = externalFactblock.map((fb) => fb.fbId);
    dispatch(
      updateSourceFbIdList({
        sourceId: doc_urls[0],
        fbIdList,
      })
    );
    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=sources&params=filters[sourceId][$eq]=${doc_urls[0]}`,
      {
        method: "GET",
      }
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;

    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error duplicate check");
    }

    if (records.length > 0) {
      await fetch("/api/v2/strapi", {
        method: "PUT",
        body: JSON.stringify({
          requestType: "update_strapi_data",
          payload: { table: "sources", data: { fbIdList }, id: records[0].id },
        }),
      });
    }

    return externalFactblock;
  }
) as any;

export const updateDocumentHtml = createAsyncThunk(
  "documents/updateDocumentHtml",
  async (params: { sourceId: string; html: string }, { dispatch, getState }) => {
    const { sourceId, html } = params;
    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=sources&params=filters[sourceId][$eq]=${sourceId}`,
      {
        method: "GET",
      }
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;

    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error duplicate check");
    }
    if (records.length > 0) {
      await fetch("/api/v2/strapi", {
        method: "PUT",
        body: JSON.stringify({
          requestType: "update_strapi_data",
          payload: { table: "sources", data: { html }, id: records[0].id },
        }),
      });
    }
    return sourceId;
  }
) as any;

export const updateChatFactblockSummary = createAsyncThunk(
  "chatContext/updateChatFactblockSummary",
  async (
    params: { chatId: string; factblockSummary: FactBlockSummary },
    { dispatch, getState }
  ) => {
    const { chatId, factblockSummary } = params;
    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=chats&params=filters[chatId][$eq]=${chatId}`,
      {
        method: "GET",
      }
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;

    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error duplicate check");
    }
    if (records.length > 0) {
      fetch("/api/v2/strapi", {
        method: "PUT",
        body: JSON.stringify({
          requestType: "update_strapi_data",
          payload: { table: "chats", data: { factblockSummary }, id: records[0].id },
        }),
      });
      dispatch(updateFactBlockSummary(factblockSummary));
    }
    return chatId;
  }
) as any;

export const deleteChat = createAsyncThunk(
  "chatContext/deleteChat",
  async (chatId: string, { dispatch, getState }) => {
    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=chats&params=filters[chatId][$eq]=${chatId}`
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;

    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error duplicate check");
    }
    fetch("/api/v2/strapi", {
      method: "DELETE",
      body: JSON.stringify({
        requestType: "delete_strapi_data",
        payload: { table: "chats", id: records[0].id },
      }),
    });
    dispatch(deleteMyChatsFromPool(chatId));

    return chatId;
  }
) as any;

export const updateChatTitleToStateAndDB = createAsyncThunk(
  "chatContext/updateChatTitleToStateAndDB",
  async (params: { chatId: string; message: string }, { dispatch, getState }) => {
    const { chatId, message } = params;
    if (!message) return;
    const title = await dispatch(fetchChatTitle(message)).unwrap();
    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=chats&params=filters[chatId][$eq]=${chatId}`,
      {
        method: "GET",
      }
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;

    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error duplicate check");
    }

    if (records.length > 0) {
      fetch("/api/v2/strapi", {
        method: "PUT",
        body: JSON.stringify({
          requestType: "update_strapi_data",
          payload: { table: "chats", data: { title }, id: records[0].id },
        }),
      });
      dispatch(updateChatTitle(title));
      dispatch(updateNewChatWithNewTitle({ chatId, title }));
    }
    return chatId;
  }
) as any;

// export const findTypeThreadHandler = createAsyncThunk(
//   "chatContext/findTypeThreadHandler",
//   async (
//     params: { message?: string; recreate?: boolean; dbData?: Message },
//     { dispatch, getState, rejectWithValue, signal }
//   ) => {
//     const { message } = params;
//     if (!message) return;
//     const state = getState() as RootState;
//     const chatId = state.chatContext.chatId;
//     const controller = new AbortController();
//     setController(chatId, controller);

//     try {
//       const UUID = messageParingIdGenerator();
//       dispatch(setPersistentType(EOperationOptions.FindType));
//       const chat = state.chatContext;
//       const filterParam = state.chatContext.findTypeFilter;
//       const findInput: FindInput = { findStringInput: message!, filterParam };

//       const facblockSummaryState = selectFactblockSummary(state);
//       const requestData = {
//         content: findInput,
//         isQuestion: true,
//         messageId: UUID.questionId,
//         operation: EOperationOptions.FindType,
//         chatId: chat.chatId,
//         facblockSummaryState,
//       };
//       await dispatch(appendMessage({ ...requestData, content: message }));

//       const responseData = {
//         formerId: UUID.responseId,
//         isQuestion: false,
//         operation: EOperationOptions.FindType,
//         messageId: UUID.responseId,
//         chatId: chat.chatId,
//         facblockSummaryState,
//       };
//       await dispatch(appendMessage(responseData));

//       await fetch("/api/v2/strapi", {
//         method: "POST",
//         body: JSON.stringify({
//           requestType: "create_strapi_data",
//           payload: { table: "messages", data: requestData },
//         }),
//       });
//       const externalFindFilter = selectExternalFindTypeFilterStateBeta(state);
//       const internal = selectInternalFindTypeFilterState(state);
//       const internalGroup = selectGroupFindTypeFilterState(state);
//       // const casenoteFilters = filterSearchParamEmptyKeys({
//       //   internal,
//       //   internalGroup,
//       //   external: externalFindFilter,
//       //   findInternal: false,
//       // });
//       const internalFilters = filterSearchParamEmptyKeys({
//         internal,
//         internalGroup,
//         external: externalFindFilter,
//         findInternal: true,
//       });

//       const fetchData = async () => {
//         let firstPartyHits;
//         let thirdPartyHits;
//         const isEmptyObject = (obj: object): boolean => {
//           return Object.keys(obj).length === 0;
//         };
//         try {
//           const search = !isEmptyObject(internalFilters);
//           firstPartyHits = search && (await dispatch(fetchInternalDoc(message)).unwrap());
//         } catch (error) {
//           console.error("Error fetching internal documents", error);
//         }

//         try {
//           // const search = !isEmptyObject(casenoteFilters);
//           // if (search) {
//           //   thirdPartyHits = await dispatch(
//           //     fetchCasenoteDocAPIM({
//           //       casenote: (casenoteFilters as TExternalFindFilter).casenote as TLegalFilter[],
//           //       query: message,
//           //     })
//           //   ).unwrap();
//           //   dispatch(
//           //     updateFoundDocument({
//           //       messageId: responseData.messageId,
//           //       content: { thirdPartyHits },
//           //     })
//           //   );
//           //   dispatch(fetchExternalFb(thirdPartyHits.map((source: Source) => source.sourceId)));
//           //   dispatch(updateShowDiffCard(true));
//           // }
//         } catch (error) {
//           console.error("Error fetching casenote documents", error);
//         }

//         const finalResponse = {
//           ...responseData,
//           content: { firstPartyHits, thirdPartyHits },
//         };

//         const finalDbInteraction = async () => {
//           await fetch("/api/v2/strapi", {
//             method: "POST",
//             body: JSON.stringify({
//               requestType: "create_strapi_data",
//               payload: { table: "messages", data: finalResponse },
//             }),
//           });
//         };
//         await finalDbInteraction();
//       };

//       await fetchData();

//       const userId = state.user.userId!;
//       return { userId };
//     } catch (error) {
//       console.log("rejectWithValue", error);

//       throw error;
//     } finally {
//       // Clean up the AbortController
//       removeController(chatId);
//     }
//   }
// ) as any;

export const findTypeThreadHandler = createAsyncThunk(
  "chatContext/findTypeThreadHandler",
  async (
    params: { message?: string; recreate?: boolean; dbData?: Message },
    { dispatch, getState, rejectWithValue, signal }
  ) => {
    const { message } = params;
    if (!message) return;
    const state = getState() as RootState;
    const chatId = state.chatContext.chatId;
    const controller = new AbortController();
    setController(chatId, controller);

    try {
      const UUID = messageParingIdGenerator();
      dispatch(setPersistentType(EOperationOptions.FindType));
      const chat = state.chatContext;
      const filterParam = state.chatContext.findTypeFilter;
      const findInput: FindInput = { findStringInput: message!, filterParam };

      const facblockSummaryState = selectFactblockSummary(state);
      const requestData = {
        content: findInput,
        isQuestion: true,
        messageId: UUID.questionId,
        operation: EOperationOptions.FindType,
        chatId: chat.chatId,
        facblockSummaryState,
      };
      await dispatch(appendMessage({ ...requestData, content: message }));

      const responseData = {
        formerId: UUID.responseId,
        isQuestion: false,
        operation: EOperationOptions.FindType,
        messageId: UUID.responseId,
        chatId: chat.chatId,
        facblockSummaryState,
      };
      await dispatch(appendMessage(responseData));

      await fetch("/api/v2/strapi", {
        method: "POST",
        body: JSON.stringify({
          requestType: "create_strapi_data",
          payload: { table: "messages", data: requestData },
        }),
      });

      const externalFindFilter = selectExternalFindTypeFilterStateBeta(state);
      const internal = selectInternalFindTypeFilterState(state);
      const internalGroup = selectGroupFindTypeFilterState(state);

      const internalFilters = filterSearchParamEmptyKeys({
        internal,
        internalGroup,
        external: externalFindFilter,
        findInternal: true,
      });

      const fetchData = async () => {
        let firstPartyHits;
        let thirdPartyHits;
        const isEmptyObject = (obj: object): boolean => {
          return Object.keys(obj).length === 0;
        };
        try {
          const search = !isEmptyObject(internalFilters);
          if (search) {
            firstPartyHits = await dispatch(
              fetchInternalDoc({ message, signal: controller.signal })
            ).unwrap();
          }
        } catch (error) {
          console.error("Error fetching internal documents", error);
        }

        const finalResponse = {
          ...responseData,
          content: { firstPartyHits, thirdPartyHits },
        };

        const finalDbInteraction = async () => {
          await fetch("/api/v2/strapi", {
            method: "POST",
            body: JSON.stringify({
              requestType: "create_strapi_data",
              payload: { table: "messages", data: finalResponse },
            }),
          });
        };
        await finalDbInteraction();
      };

      await fetchData();

      const userId = state.user.userId!;
      return { userId };
    } catch (error) {
      throw error;
    } finally {
      removeController(chatId);
    }
  }
) as any;

export const reflectTextHandler = createAsyncThunk(
  "chatContext/reflectTextHandler",
  async (
    params: { text_input: string; prompt: string },
    { dispatch, getState, rejectWithValue }
  ) => {
    const UUID = messageParingIdGenerator();
    const { text_input, prompt } = params;
    const state = getState() as RootState;
    const chat = state.chatContext;
    const facblockSummaryState = selectFactblockSummary(state);
    const chatId = chat.chatId;

    if (!text_input) return;
    if (!prompt) return;

    const controller = new AbortController();
    setController(chatId, controller);

    try {
      dispatch(setPersistentType(EOperationOptions.ReflectType));
      const requestData = {
        content: { text_input, prompt },
        isQuestion: true,
        messageId: UUID.questionId,
        operation: EOperationOptions.ReflectType,
        chatId: chat.chatId,
        facblockSummaryState,
      };
      await dispatch(appendMessage(requestData));

      const responseData = {
        formerId: UUID.questionId,
        isQuestion: false,
        operation: EOperationOptions.ReflectType,
        messageId: UUID.responseId,
        chatId: chat.chatId,
        facblockSummaryState,
      };
      await dispatch(appendMessage(responseData));
      const res: { factblock: FactBlock[]; source: Source[] } = await dispatch(
        generateUserFactblockFromText({
          prompt,
          text_input,
          responseId: UUID.responseId,
          signal: controller.signal,
        })
      ).unwrap();
      dispatch(updateShowDiffCard(true));

      await fetch("/api/v2/strapi", {
        method: "POST",
        body: JSON.stringify({
          requestType: "create_strapi_data",
          payload: { table: "messages", data: { ...requestData, content: { text_input, prompt } } },
        }),
      });
      await fetch("/api/v2/strapi", {
        method: "POST",
        body: JSON.stringify({
          requestType: "create_strapi_data",
          payload: { table: "messages", data: { ...responseData, content: res } },
        }),
      });
      const userId = state.user.userId!;
      return { userId };
    } catch (error) {
      throw error;
    } finally {
      removeController(chatId);
    }
  }
) as any;

export const generateUserFactblockFromText = createAsyncThunk(
  "reflect/generateUserFactblockFromText",
  async (
    params: { prompt: string; text_input: string; responseId: string; signal: AbortSignal },
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const chat = state.chatContext;
    const userId = state.user.userId!;
    const factblockSummary = selectFactblockSummary(getState());
    const { prompt, text_input, responseId, signal } = params;
    try {
      const resource = (await fetchFactblock({
        requestType: "reflect_user_text_to_fb",
        message: prompt,
        text_input,
        factblockMapper: userGeneratedFactblockMapper(responseId),
        signal,
      })) as ReflectResponse;
      const { factblock, source } = resource;
      if (!resource) return;
      dispatch(disableAbortFactagorReflect());
      const fbIdList = factblock.map((fb) => fb.fbId);
      const newFBSummary = {
        ...factblockSummary,
        Approval: [...factblockSummary[Annotation.Approval], ...fbIdList],
      };
      dispatch(updateFactBlockSummary(newFBSummary));
      dispatch(
        updateChatFactblockSummary({ ...state.chatContext, factblockSummary: newFBSummary })
      );
      dispatch(
        updateReflectedFactblock({
          messageId: responseId,
          content: resource as ReflectResponse,
        })
      );
      insertFactblocksToDB({ fbs: factblock, chatId: chat.chatId, userId });
      dispatch(upsertFactblockListToPool(factblock));
      insertSourcesToDB(source.map((source) => ({ ...source, userId })));
      dispatch(upsertSourceListToPool(source.map((source) => ({ ...source, userId }))));
      return { factblock, source };
    } catch (error) {
      if (error.message === AbortCodes.AbortFactagoraReflect) {
        return rejectWithValue(AbortCodes.AbortFactagoraReflect);
      }
    }
  }
) as any;

export const reflectFileHandler = createAsyncThunk(
  "chatContext/reflectFileHandler",
  async (
    { params }: { params: { message: string; prompt: string; file: File } },
    { dispatch, getState, rejectWithValue }
  ) => {
    const { message, prompt, file } = params;
    const UUID = messageParingIdGenerator();
    const state = getState() as RootState;
    const chat = state.chatContext;
    const chatId = chat.chatId;
    const facblockSummaryState = selectFactblockSummary(state);
    if (!message) return; // used as file name
    if (!prompt) return;

    const controller = new AbortController();
    setController(chatId, controller);
    try {
      dispatch(setPersistentType(EOperationOptions.ReflectType));

      const requestData = {
        content: { text_input: message, prompt },
        isQuestion: true,
        messageId: UUID.questionId,
        operation: EOperationOptions.ReflectType,
        chatId: chat.chatId,
        facblockSummaryState,
      };
      await dispatch(appendMessage({ ...requestData, content: { text_input: message, prompt } }));

      const responseData = {
        formerId: UUID.questionId,
        isQuestion: false,
        operation: EOperationOptions.ReflectType,
        messageId: UUID.responseId,
        chatId: chat.chatId,
        facblockSummaryState,
      };
      await dispatch(appendMessage(responseData));
      const res = await dispatch(
        generateUserFactblockFromFile({
          message,
          file,
          prompt,
          responseId: UUID.responseId,
          signal: controller.signal,
        })
      ).unwrap();
      dispatch(updateShowDiffCard(true));

      await fetch("/api/v2/strapi", {
        method: "POST",
        body: JSON.stringify({
          requestType: "create_strapi_data",
          payload: {
            table: "messages",
            data: { ...requestData, content: { text_input: message, prompt } },
          },
        }),
      });

      await fetch("/api/v2/strapi", {
        method: "POST",
        body: JSON.stringify({
          requestType: "create_strapi_data",
          payload: { table: "messages", data: { ...responseData, content: res } },
        }),
      });
      const userId = state.user.userId!;
      return { userId, responseData };
    } catch (error) {
      throw error;
    } finally {
      removeController(chatId);
    }
  }
) as any;

export const generateUserFactblockFromFile = createAsyncThunk(
  "reflect/generateUserFactblockFromFile",
  async (
    params: {
      message: string;
      file: File;
      prompt: string;
      responseId: string;
      signal: AbortSignal;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const chat = state.chatContext;
    const userId = state.user.userId!;
    const factblockSummary = selectFactblockSummary(getState());
    const { message, file, prompt, responseId, signal } = params;
    // console.log("HASH::", await computeFileHash(file));
    try {
      const resource = (await fetchFactblock({
        requestType: "reflect_user_file_to_fb",
        message,
        prompt,
        file_input: file,
        factblockMapper: userGeneratedFactblockMapper(responseId, file.name),
        signal,
      })) as { factblock: FactBlock[]; source: Source[] };
      const { factblock, source } = resource;
      if (!resource) return;
      dispatch(disableAbortFactagorReflect());
      const fbIdList = factblock.map((fb) => fb.fbId);
      const newFBSummary = {
        ...factblockSummary,
        Approval: [...factblockSummary[Annotation.Approval], ...fbIdList],
      };
      dispatch(updateFactBlockSummary(newFBSummary));
      dispatch(
        updateChatFactblockSummary({ ...state.chatContext, factblockSummary: newFBSummary })
      );
      dispatch(
        updateReflectedFactblock({
          messageId: responseId,
          content: resource as ReflectResponse,
        })
      );
      insertFactblocksToDB({ fbs: factblock, chatId: chat.chatId, userId });
      dispatch(upsertFactblockListToPool(factblock));
      insertSourcesToDB(source.map((source) => ({ ...source, userId })));
      dispatch(upsertSourceListToPool(source.map((source) => ({ ...source, userId }))));
      return { factblock, source };
    } catch (error) {
      if (error.message === AbortCodes.AbortFactagoraReflect) {
        return rejectWithValue(AbortCodes.AbortFactagoraReflect);
      }
    }
  }
) as any;

export const upsertAnnotation = createAsyncThunk(
  "chatContext/upsertAnnotation",
  async (
    { explicitAnnotation }: { explicitAnnotation?: { fbId: string; annotation: Annotation } },
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const annotator = state.user.userId!;
    let GAResponse: TAnnotationEvent[] = [];

    const upsertAnnotationForFbId = async (fbId: string, annotation: Annotation) => {
      const response = await fetch(
        `/api/v2/strapi?requestType=fetch_strapi_data&table=annotations&params=filters[fbId][$eq]=${fbId}`,
        { method: "GET" }
      );
      const responseData: TPromiseResponse = await response.json();
      const { statusCode, status, records } = responseData;

      if (status !== "success" || statusCode !== 200) {
        throw new Error("Error processing annotation for fbId: " + fbId);
      }

      if (records.length > 0) {
        await fetch("/api/v2/strapi", {
          method: "PUT",
          body: JSON.stringify({
            requestType: "update_strapi_data",
            payload: {
              table: "annotations",
              data: { annotation, annotator },
              id: records[0].id,
            },
          }),
        });
        return {
          annotationId: records[0].id,
          fbId,
          annotation,
          userId: annotator,
        };
      } else {
        const newId = uuidv4();
        await fetch("/api/v2/strapi", {
          method: "POST",
          body: JSON.stringify({
            requestType: "create_strapi_data",
            payload: {
              table: "annotations",
              data: { annotation, fbId, annotationId: newId, annotator },
            },
          }),
        });
        return {
          annotationId: newId,
          fbId,
          annotation,
          userId: annotator,
        };
      }
    };

    try {
      if (explicitAnnotation) {
        const result = await upsertAnnotationForFbId(
          explicitAnnotation.fbId,
          explicitAnnotation.annotation
        );
        GAResponse.push(result);
      } else {
        const factBlockSummary = state.chatContext.factblockSummary;
        for (const [annotation, fbIdList] of Object.entries(factBlockSummary)) {
          if (Array.isArray(fbIdList)) {
            for (const fbId of fbIdList) {
              const result = await upsertAnnotationForFbId(fbId, annotation as Annotation);
              GAResponse.push(result);
            }
          }
        }
      }

      GAResponse.forEach(GAFBAnnotationEvent);
    } catch (error) {
      return rejectWithValue({ error: (error as Error).message });
    }
  }
) as any;

export const createChatRoom = createAsyncThunk(
  "chatContext/createChatRoom",
  async (message: string, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const user = state.user;
    const userId = user.userId!;
    try {
      const chatId = uuidv4();
      await dispatch(initiateChatInstance({ chatId }));

      fetch("/api/v2/strapi", {
        method: "POST",
        body: JSON.stringify({
          requestType: "create_strapi_data",
          payload: {
            table: "chats",
            data: {
              chatId,
              latestOperator,
              factblockSummary: initialChatState.factblockSummary,
              context: [],
              userId,
              title: "",
            },
          },
        }),
      });
      dispatch(updateNewChat(chatId));
      GACreateChatEvent({
        chatId,
        userId,
      });
      return chatId;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
) as any;

export const duplicateChatRoom = createAsyncThunk(
  "chatContext/duplicateChatRoom",
  async (originalChatId: string, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const userId = state.user.userId;
      const newChatId = uuidv4();
      const originalChat = await fetchChat(originalChatId);
      const newChat = { ...originalChat, chatId: newChatId };
      const originalMessages = await fetchMessages(originalChatId);
      const newMessages = originalMessages.map((message: Message) => ({
        ...message,
        messageId: uuidv4(),
        chatId: newChatId,
      }));

      const newChatInstance = {
        ...initialChatState,
        ...newChat,
        context: newMessages,
        userId,
      };

      await fetch("/api/v2/strapi", {
        method: "POST",
        body: JSON.stringify({
          requestType: "create_strapi_data",
          payload: { table: "chats", data: newChatInstance },
        }),
      });

      newMessages.map(async (message: Message) =>
        fetch("/api/v2/strapi", {
          method: "POST",
          body: JSON.stringify({
            requestType: "create_strapi_data",
            payload: { table: "messages", data: message },
          }),
        })
      );

      dispatch(updateCurrentChatState(newChatInstance as ChatContext));
      dispatch(updateLatestOperator(EOperationOptions.AnswerType));

      return newChatInstance;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
) as any;

const fetchStrapiAndCacheToSourcePool = createAsyncThunk(
  "chatContext/fetchStrapiAndCacheToSourcePool",
  async (params: { table: TTable; filter: string; equals: string }, { dispatch }) => {
    const { table, filter, equals } = params;
    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=${table}&params=filters[${filter}][$eq]=${equals}`
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;
    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error fetching Source from Strapi");
    }

    if (records.length > 0) {
      dispatch(upsertSourceListToPool(records));
    }
  }
);

const fetchStrapiAndCacheToFactblockPool = createAsyncThunk(
  "chatContext/fetchStrapiAndCacheToFactblockPool",
  async (params: { table: TTable; filter: string; equals: string }, { dispatch }) => {
    const { table, filter, equals } = params;
    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=${table}&params=filters[${filter}][$eq]=${equals}`
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;
    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error fetching FB from Strapi");
    }

    if (records.length > 0) {
      dispatch(upsertFactblockListToPool(records));
      const sourceIdList = records.map((fb: FactBlock) => fb.sourceId);
      for (const sourceId of sourceIdList) {
        await dispatch(
          fetchStrapiAndCacheToSourcePool({
            table: "sources",
            filter: "sourceId",
            equals: sourceId,
          })
        );
      }
    }
  }
);

export const updateCurrentChat = createAsyncThunk(
  "chatContext/updateCurrentChat",
  async (originalChatId: string, { dispatch, getState, rejectWithValue }) => {
    dispatch(clearThreadAndAnnotation());
    try {
      const state = getState() as RootState;
      const userId = state.user.userId;
      const originalChat: ChatContext = await fetchChat(originalChatId);
      let originalMessages = await fetchMessages(originalChatId);
      let latestMessage = _.maxBy(originalMessages, "updateTime");
      const dispatchFetchForSources = (sources: Source[]) => {
        sources.forEach((source) => {
          dispatch(
            fetchStrapiAndCacheToSourcePool({
              table: "sources",
              filter: "sourceId",
              equals: source.sourceId,
            })
          );
        });
      };

      const dispatchFetchForFactblockSummary = (fbIdList: string[]) => {
        fbIdList.forEach((fbId) => {
          dispatch(
            fetchStrapiAndCacheToFactblockPool({
              table: "factblocks",
              filter: "fbId",
              equals: fbId,
            })
          );
        });
      };

      originalMessages = originalMessages.filter((m) => !m.content.error);
      dispatchFetchForFactblockSummary(Object.values(originalChat.factblockSummary).flat());
      for (let message of originalMessages) {
        if (
          latestMessage.operation === EOperationOptions.AnswerType &&
          latestMessage.messageId === message.messageId
        ) {
          if (message.content.reference) {
            const references = groupByReferenceAndGenerateSource(message.content.reference);
            dispatch(upsertSourceListToPool(references.sources));
            const factblock = mapReferenceToFb(message.content.reference);
            dispatch(upsertFactblockListToPool(factblock));
          }
          // console.log("updateCurrentChat::", message.content);
        }

        if (
          message.operation === EOperationOptions.FindType &&
          latestMessage.messageId === message.messageId
        ) {
          if (Array.isArray(message.content.firstPartyHits)) {
            dispatchFetchForSources(message.content.firstPartyHits);
          }
          if (Array.isArray(message.content.thirdPartyHits)) {
            dispatchFetchForSources(message.content.thirdPartyHits);
          }
        }

        if (
          message.operation === EOperationOptions.ReflectType &&
          latestMessage.messageId === message.messageId
        ) {
          if (message.content.factblock) {
            dispatch(upsertFactblockListToPool(message.content.factblock));
          }
        }
      }
      const chatReplicate = {
        ...originalChat,
        context: _.sortBy(originalMessages, "updateTime", "asc"),
        userId: userId!,
        chatId: originalChatId,
      };
      dispatch(updateCurrentChatState(chatReplicate));
      return originalMessages;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
) as any;

export const getMyChats = createAsyncThunk<any>(
  "chatContext/getMyChats",
  async (length, { dispatch, getState, rejectWithValue }) => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const formattedDate = oneMonthAgo.toISOString();
    try {
      const state = getState() as RootState;
      const userId = state.user.userId;
      // const userId = "AIlogin@shinkim.com";
      const sortField = "updateTime";
      const sortOrder = "desc";
      const limit = 25;
      if (!userId) return;
      const url = `/api/v2/strapi?requestType=fetch_strapi_data&table=chats&params=filters[userId][$eq]=${userId}&filters[updateTime][$gte]=${formattedDate}&sort[0]=${sortField}:${sortOrder}&pagination[start]=${length}&pagination[limit]=${limit}`;
      const myChats = await fetch(url);
      const responseData: TPromiseResponse = await myChats.json();
      const { statusCode, status, records } = responseData;

      if (status !== "success" || statusCode !== 200) {
        throw new Error("Error fetching my chats");
      }
      dispatch(upsertMyChatsToPool(records));
      return records;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
) as any;

export const getNewChatFromDB = createAsyncThunk<any>(
  "chatContext/getNewChatFromDB",
  async (chatId, { dispatch, getState, rejectWithValue }) => {
    try {
      const url = `/api/v2/strapi?requestType=fetch_strapi_data&table=chats&params=filters[chatId][$eq]=${chatId}`;
      const newEntry = await fetch(url);
      const responseData: TPromiseResponse = await newEntry.json();
      const { statusCode, status, records } = responseData;

      if (status !== "success" || statusCode !== 200) {
        throw new Error("Error fetching my chats");
      }

      dispatch(upsertMyChatsToPool(records));
      return records;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
) as any;

export const toggleMyChatPinned = createAsyncThunk<any>(
  "chatContext/toggleMyChatPinned",
  async ({ chatId, chat }: any, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    try {
      const updateTargetChat = await fetch(
        `/api/v2/strapi?requestType=fetch_strapi_data&table=chats&params=filters[chatId][$eq]=${chatId}`,
        {
          method: "GET",
        }
      );
      const responseData: TPromiseResponse = await updateTargetChat.json();
      const { statusCode, status, records } = responseData;

      if (status !== "success" || statusCode !== 200) {
        throw new Error("Error duplicate check");
      }

      const target: ChatContext = records[0];
      fetch("/api/v2/strapi", {
        method: "PUT",
        body: JSON.stringify({
          requestType: "update_strapi_data",
          payload: {
            table: "chats",
            data: { isPinned: !target.isPinned },
            id: records[0].id,
          },
        }),
      });
      dispatch(getMyChats());
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
) as any;

export const fetchChatTitle = createAsyncThunk(
  "createChatRoom/fetchChatTitle",
  async (message: string, { dispatch, getState, rejectWithValue }) => {
    const newChatTitle = await fetch(`/api/v1/factBlock`, {
      method: "POST",
      body: JSON.stringify({
        requestType: "chat_title",
        payload: { message },
      }),
    });
    const _responseData = JSON.parse(await newChatTitle.text());
    const formattedTitle = _responseData.data.replace(/"/g, "");
    return formattedTitle;
  }
) as any;

export const submitFeedback = createAsyncThunk(
  "chatContext/submitFeedback",
  async (_feedback: Partial<FeedbackForm>, { dispatch, getState, rejectWithValue }) => {
    try {
      const feedbackId = uuidv4();
      let feedback = { ..._feedback, feedbackId };
      const state = getState() as RootState;
      const user = state.user;
      const chatId = state.chatContext.chatId;
      const userId = user.userId!;
      const partnerId = reverseString(feedback.messageId!);

      const query = state.chatContext.context.find((m: any) => m.messageId === partnerId)
        ?.content as string;
      const newFeedback: FeedbackForm = {
        satisfaction: feedback.satisfaction ?? "",
        featureOpinion: feedback.featureOpinion ?? [],
        systemOpinion: feedback.systemOpinion ?? [],
        messageId: feedback.messageId ?? "",
        feedbackId,
        chatId,
        userId,
      };
      const satisfaction = newFeedback.satisfaction;
      const featureOpinion = newFeedback.featureOpinion;
      const systemOpinion = newFeedback.systemOpinion;

      fetch("/api/v2/strapi", {
        method: "POST",
        body: JSON.stringify({
          requestType: "create_strapi_data",
          payload: { table: "feedbacks", data: newFeedback },
        }),
      });

      GACreateFeedbackEvent({
        userId,
        feedbackId,
        query: query ?? "",
        satisfaction,
        featureOpinion,
        systemOpinion,
      });

      return feedbackId;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
) as any;

export const fetchIsSummaryDiff = createAsyncThunk(
  "chatContext/fetchIsSummaryDiff",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const secondToLatestMessageId = selectOldResponseMessageId(2)(state);
    const secondToLatestFBSummaryState =
      selectFactblockSummaryState(secondToLatestMessageId)(state);
    const differences = compareFactBlockSummaries(
      secondToLatestFBSummaryState ?? initialFactblockSummary,
      state.chatContext.factblockSummary
    );
    return checkNonZeroValues(differences);
  }
) as any;

export const startNewChat = createAsyncThunk(
  "chatContext/startNewChat",
  async (_, { getState, dispatch }) => {
    const type = EOperationOptions.AnswerType;
    dispatch(setPersistentType(type));
    dispatch(clearThreadAndAnnotation());
    dispatch(updateCreateChatState(true));
    dispatch(updateMenuRouter(null));
  }
) as any;

export const {
  initiateChatInstance,
  clearThreadAndAnnotation,
  clearAPIState,
  appendMessage,
  updateMessageContent,
  throwFactagoraAnswerError,
  updateLatestOperator,
  updateFactBlockSummary,
  updateOriginalFactBlockSummary,
  throwSuperlawyerError,
  updateInternalFindFilter,
  updateExternalFindFilter,
  updateExternalAnswerFilter,
  updateInternalAnswerFilter,
  updateGroupAnswerFilter,
  updateGroupFindFilter,
  updateDeepNestedField,
  updateCurrentChatState,
  updateFoundDocument,
  updateReflectedFactblock,
  isAnswerStreaming,
  updateMessageFactblockSummaryState,
  updateShowDiffCard,
  recreateMessage,
  updateExternalFindFilterBeta,
  toggleSideMenuOpenState,
  updateSideMenuHideState,
  updateChatTitle,
  updateCreateChatState,
  updateBookmarkShowState,
  setCallbackData,
  updateActiveMessage,
  updateLLMModel,
  disableAbortFactagoraFind,
  disableAbortFactagorReflect,
} = chatContextSlice.actions;

export default chatContextSlice.reducer;
