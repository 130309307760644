type TPromiseResponse = { statusCode: number; status: string; records: any[] };
import { AbortCodes, CallbackCodes } from "@/globalEnum";

function toBase64(str: string): string {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );
}

function fromBase64(base64: string): string {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(base64), (c) => `%${c.charCodeAt(0).toString(16).padStart(2, "0")}`)
      .join("")
  );
}

type TFetchFactblockParams = {
  requestType: string;
  message: string;
  factblockMapper: (
    responseBody: string
  ) => FactBlock[] | { factblock: FactBlock[]; source: Source[] };
  doc_ids?: string[];
  doc_urls?: string[];
  doc_url?: string;
  text_input?: string;
  file_input?: File;
  legal_doc_type?: string;
  prompt?: string;
  index?: string;
  namespace?: string;
  signal?: AbortSignal;
};

type TFetchDocumentParams = {
  requestType: string;
  documentMapper: (responseBody: string) => Source[];
  message: string;
  filter: Filter;
  signal?: AbortSignal;
};

const getFileMimeType = (file: any) => {
  const extension = file.name.split(".").pop().toLowerCase();
  if (extension === "hwp") {
    return "application/x-hwp";
  }
  return file.type;
};

async function insertSourcesToDB(sources: Source[]) {
  const promises = sources.map(async (source) => {
    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=sources&params=filters[sourceId][$eq]=${source.sourceId}`,
      {
        method: "GET",
      }
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;
    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error duplicate check");
    }
    if (records.length > 0) {
      // console.error("Duplicate exists");
      return;
    }
    return await fetch("/api/v2/strapi", {
      method: "POST",
      body: JSON.stringify({
        requestType: "create_strapi_data",
        payload: {
          table: "sources",
          data: source,
        },
      }),
    });
  });
  return Promise.all(promises);
}

async function insertFactblocksToDB({
  fbs,
  chatId,
  userId,
  table,
}: {
  fbs: FactBlock[];
  chatId: string;
  userId: string;
  table?: string;
}) {
  const promises = fbs.map(async (fb) => {
    const response = await fetch(
      `/api/v2/strapi?requestType=fetch_strapi_data&table=factblocks&params=filters[fbId][$eq]=${fb.fbId}`,
      {
        method: "GET",
      }
    );
    const responseData: TPromiseResponse = await response.json();
    const { statusCode, status, records } = responseData;
    if (status !== "success" || statusCode !== 200) {
      throw new Error("Error duplicate check");
    }

    if (records.length > 0) {
      // console.error("Duplicate factblock exists");
      return;
    }
    return await fetch("/api/v2/strapi", {
      method: "POST",
      body: JSON.stringify({
        requestType: "create_strapi_data",
        payload: { table: table ?? "factblocks", data: { ...fb, chatId, userId } },
      }),
    });
  });
  return Promise.all(promises);
}

async function fetchDocument(params: TFetchDocumentParams) {
  const { requestType, documentMapper, message, filter, signal } = params;
  let payload;
  try {
    if (requestType === "retrieve_internal_docfbs") {
      payload = {
        requestType,
        message,
        filter,
        documentMapper,
      };
    }
    const response = await fetch("/api/v1/factBlock/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        requestType,
        payload: {
          message,
          params: filter,
        },
      }),
      signal,
    });

    if (!response.ok) throw new Error("No response body");

    const contentEncoding = response.headers.get("Content-Encoding");
    let responseBody;

    if (contentEncoding === "gzip" || contentEncoding === "deflate" || contentEncoding === "br") {
      const arrayBuffer = await response.arrayBuffer();
      responseBody = new TextDecoder().decode(arrayBuffer);
    } else {
      responseBody = await response.text();
    }

    return documentMapper(responseBody);
  } catch (error) {
    if (signal?.aborted) {
      throw new Error(AbortCodes.AbortFactagoraFind);
    }
    console.error(`Error fetching sources from ${requestType}:`, error);
    throw new Error(`Error fetching sources from ${requestType}`);
  }
}

// Helper function to read File as ArrayBuffer using Promises
const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.result instanceof ArrayBuffer) {
        resolve(fileReader.result);
      } else {
        reject(new Error("FileReader result is not an ArrayBuffer"));
      }
    };
    fileReader.onerror = () => {
      reject(new Error("Error reading file"));
    };
    fileReader.readAsArrayBuffer(file);
  });
};

async function fetchFactblock(params: TFetchFactblockParams) {
  const {
    requestType,
    message,
    text_input,
    factblockMapper,
    doc_ids,
    doc_urls,
    doc_url,
    legal_doc_type,
    file_input,
    prompt,
    index,
    namespace,
    signal,
  } = params;

  try {
    let payload: any = {};

    // 각 requestType에 따른 payload 설정
    switch (requestType) {
      case "retrieve_internal_chunkfbs":
        payload = {
          index,
          namespace,
          message,
          doc_ids,
        };
        break;
      case "retrieve_casenote_pagefbs":
        payload = {
          message,
          doc_urls,
        };
        break;
      case "generate_casenote_pagefbs":
        payload = {
          message,
          doc_url,
          legal_doc_type,
        };
        break;
      case "reflect_user_text_to_fb":
        payload = {
          message,
          text_input,
        };
        break;
      case "reflect_user_file_to_fb":
        if (!file_input) {
          throw new Error("File input is required for reflect_user_file_to_fb");
        }

        // 파일을 ArrayBuffer로 읽기
        const fileArrayBuffer = await readFileAsArrayBuffer(file_input);

        // 파일 이름과 쿼리 인코딩
        const encodedFileName = toBase64(message);
        const encodedQuery = toBase64(prompt!);

        // v2 엔드포인트 호출
        const v2Response = await fetch("/api/v2/factBlock", {
          // 서버 API Route 엔드포인트
          method: "POST",
          body: fileArrayBuffer, // 바이너리 데이터 전송
          headers: {
            "Content-Type": "application/octet-stream", // 바이너리 데이터임을 명시
            "Accept-Encoding": "gzip, deflate, br",
            "x-file-mime-type": getFileMimeType(file_input), // MIME 타입 설정
            "x-original-filename": encodedFileName,
            query: encodedQuery,
          },
          signal,
        });

        if (!v2Response.ok) {
          const errorText = await v2Response.text();
          throw new Error(`Error in v2 factBlock API: ${errorText}`);
        }

        const v2Data = await v2Response.json();
        // v2 응답을 매핑
        return factblockMapper(JSON.stringify(v2Data));

      default:
        throw new Error(`Unsupported requestType: ${requestType}`);
    }

    // v1 엔드포인트 호출
    const v1Response = await fetch("/api/v1/factBlock/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        requestType,
        payload,
      }),
      signal,
    });

    if (!v1Response.ok) throw new Error("No response body");

    const contentEncoding = v1Response.headers.get("Content-Encoding");
    let responseBody: string;

    if (contentEncoding === "gzip" || contentEncoding === "deflate" || contentEncoding === "br") {
      const arrayBuffer = await v1Response.arrayBuffer();
      responseBody = new TextDecoder().decode(arrayBuffer);
    } else {
      responseBody = await v1Response.text();
    }

    if (responseBody === CallbackCodes.CheckFBExists) {
      return CallbackCodes.GenerateFB;
    }

    const factblockList = factblockMapper(responseBody);
    return factblockList;
  } catch (error: any) {
    if (signal?.aborted) {
      throw new Error(AbortCodes.AbortFactagoraReflect);
    }
    console.error(`Error fetching fb from ${requestType}:`, error);
    throw new Error(`Error fetching fb from ${requestType}`);
  }
}

async function fetchChat(originalChatId: string) {
  const originalChat = await fetch(
    `/api/v2/strapi?requestType=fetch_strapi_data&table=chats&params=filters[chatId][$eq]=${originalChatId}`,
    {
      method: "GET",
    }
  );
  const responseData: TPromiseResponse = await originalChat.json();
  const { statusCode, status, records } = responseData;

  if (status !== "success" || statusCode !== 200) {
    throw new Error("Error finding chat to duplicate");
  }
  return records[0];
}

/** used in updateCurrentChat for time travel*/
async function fetchMessages(originalChatId: string) {
  const sortField = "updateTime";
  const sortOrder = "asc";
  const originalMessages = await fetch(
    `/api/v2/strapi?requestType=fetch_strapi_data&table=messages&params=filters[chatId][$eq]=${originalChatId}&_sort=${sortField}:${sortOrder}`,
    {
      method: "GET",
    }
  );
  const responseData: TPromiseResponse = await originalMessages.json();
  let { statusCode, status, records } = responseData;

  if (status !== "success" || statusCode !== 200) {
    throw new Error("Error finding chat to duplicate");
  }
  return records.map((record) => {
    record.content = cleanContent(record.content);
    return record;
  });
}

type PossibleResponseTypes = {
  [key: string]: any;
};

const cleanContent = (content: PossibleResponseTypes) => {
  //find type
  if (content.findStringInput) {
    content = content.findStringInput;
  }
  return content;
};

export {
  fetchMessages,
  fetchChat,
  fetchFactblock,
  fetchDocument,
  insertSourcesToDB,
  insertFactblocksToDB,
};
