// src/utils/abortControllerManager.ts

// Define the type for the controllers object
const controllers: Record<string, AbortController> = {};

/**
 * Sets an AbortController for a given key.
 * @param key - A unique identifier for the AbortController.
 * @param controller - The AbortController instance to store.
 */
export const setController = (key: string, controller: AbortController): void => {
  controllers[key] = controller;
  console.log("setController", controllers);
};

/**
 * Retrieves the AbortController associated with the given key.
 * @param key - The unique identifier for the AbortController.
 * @returns The AbortController instance if found, otherwise undefined.
 */
export const getController = (key: string): AbortController | undefined => {
  return controllers[key];
};

/**
 * Removes the AbortController associated with the given key.
 * @param key - The unique identifier for the AbortController to remove.
 */
export const removeController = (key: string): void => {
  delete controllers[key];
};

/**
 * Aborts a request by key.
 * @param key - The unique identifier for the request.
 */
export const abortRequest = (key: string): void => {
  const controller = controllers[key];
  if (controller) {
    console.log("abortRequest", controller);

    controller.abort();
    removeController(key);
  }
};
